$table-border: 2px solid #c9c5b9;

.table {
  --width-first-col: 70%;
  margin-top: 25px;
  
  .table__title {
    line-height: 21.6px;
  }
  

  &--boards {
    & .table__row {
      display: grid;
      grid-template-columns: 1fr 15% 10% 20%;
      & .table__col {
        width: 100%;
      }
    }
  }

  &--thread {
    & .table__row {
      display: grid;
      grid-template-columns: 1fr 25% 7% 27%;
      padding: 2px 0px;
      & .table__col {
        width: 100%;
        &:last-of-type {
          text-align: right;
        }
        a {
          padding-left: 0;
        }
      }
    }

    .user-link .user-link__name {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 110px;
      overflow: hidden;
    }
  }


  &--favorites {
    &-categories {
      @include table-grid-2col;
    }

    &-boards {
      @include table-grid-4col;
      & .table__row {
        grid-template-columns: 1fr 15% 20% 10%;
      }
    }

    &-authors {
      @include table-grid-3col;
    }

    &-threads {
      @include table-grid-4col;
      & .table__row {
        grid-template-columns: 1fr 15% 20% 10%;
        .table__col {
          padding: 2px 8px;
        }
      }
    }
  }

  &--alerts {
    @include table-grid-3col;
  }

  &--reply {
    @include table-grid-3col;

    .table__body {
      border-bottom: 1px solid #c9c5b9;
    }
    .table__row {
      grid-template-columns: 1fr 24% 27%;

      & .table__col:last-of-type {
          text-align: left;
        }

      &.active {
        background: #ffc;
      }
    }
    .table__col-subject {
      display: flex;
      align-items: center;
    }
    .table__col-subject-indicator {
      margin-right: 4px;
    }

    .table__reply-autor-link {
      display: inline-flex;
      align-items: center;
    }

    .table__reply-autor-img {
      width: 15px;
      height: 20px;
      margin-right: 5px;
      img {
        height: 100%;
      }
    }

    .table__reply-autor-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 110px;
      overflow: hidden;
    }
  }
}


.table__title {
  line-height: 21.6px;
}

.table__row {
  display: flex;
  align-items: center;
  border-top: 1px solid #ececec;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &--header {
    font-weight: 700;
    border-bottom: $table-border;
    border-top: 0;
  }

  &:last-of-type {
    //border-top: 1px solid #ececec;
  }


  a {
    padding-left: 23px;
    svg {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.table__col {
  position: relative;
  width: 15%;
  padding: 2px 8px;
  box-sizing: border-box;
  &:first-of-type {
    width: var(--width-first-col)
  }
}

.table__more {
  border-top: 1px solid #c9c5b9;
  padding-top: 4px;
}


.table__more-link {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -7px;
    top: 50%;
    transform: rotate(45deg) translate(0%, -50%);
    height: 7px;
    width: 7px;
    border-top: 2px solid $link-color;
    border-right: 2px solid $link-color;
    box-sizing: border-box;
    transition: .2s ease;
  }

  &:hover {
    text-decoration: none;
    &::after {
      right: -9px;
    }
  }

}

.table thead th, .table>tbody>tr:last-child{
  border-bottom: none;
}

table.table.table {
  &--category{
    margin-top: 5px;
  }
  &--thread{
    margin-top: 5px;
  }
}