aside > .board.tools {
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  li {
  }
}

ul.tools {
  color: $link-color;
  &.category {
    > li {
      display: inline-block;
      margin-right: 16px;
    }
  }
  &.board {
    > li {
      margin-bottom: 4px;
    }
  }
  > li > .widget.loading {
      margin-left: 20px;
  }
}
