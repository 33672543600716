.suggestion {
  position: absolute;
  z-index: 10;
  background: #fff;
  top: 97%;
  width: 350px;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid #a9a9a9;
  border-radius: 0 0 6px 6px;
  border-top: 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}


.suggestion__list {
  max-height: 550px;
  overflow: hidden;
  overflow-y: auto;
}

.suggestion__item {
  cursor: pointer;
  height: 30px;
  padding: 5px 10px;
  box-sizing: border-box;
}

.suggestion__notice {
  padding: 5px 10px;
}
