.page.create-board-suggestion {

  .fieldset > .field {
    margin-top: 16px;
  }

  .controls {
    margin-top: 16px;
    > button {
      margin-right: 8px;
    }
  }
}
