.attachments-thread {}

.attachments-thread__title {
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 23.2px;
  font-size: 16px;
}

.attachments-thread__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.attachments-thread__item {
  position: relative;
  background-color: rgba(0,0,0,0.15);
  margin: 0 8px 2px 0;
  height: 120px;
  width: 120px;
  border-radius: 8px;
  box-shadow: 0 0 0 3px rgba(0,0,0,0);
  overflow: hidden;
  transform: scale(1);
  transition: .4s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 0px 5px 0px rgba(0,0,0,.1);
  } 

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .4s ease;
  }
}