body > [role="dialog"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boards-modal {
  .MuiDialogTitle-root {
    padding: 0;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    color: white;
  }

  h2 {
    background-color: #3d77cb;
    border: none;
    color: white;
    padding: 4px 8px;
    font-size: 18px;
  }
}
