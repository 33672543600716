#boards-ui.is-admin-toolbar-enabled .page.favorites {
    max-width: 990px;
    margin: 0 auto;
}

#boards-ui.is-admin-toolbar-disabled .page.favorites {
    @include wrap-desktop;
    padding-top: 120px;
    padding-bottom: 20px;
}

.page__header {
    padding: 10px;

    p {
        font: 20px/1.45 "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;

        i {
            margin: 0 5px;
        }
    }
    .main__title {
        font-size: 36px;
        font-weight: bold;
        line-height: 1;
        padding: 5px 0;
        position: relative;
    }
}

.aside__menu__title {
    border-bottom: 1px dotted #c3be7f;
    padding-bottom: 4px;
}

.aside__menu {
    li {
        color: #005c7d;
        padding: 4px;
        cursor: pointer;

        &:hover {
            color: #0079a3;
        }

        &.active {
            background: #afbc22;
            a {
                color: #fff;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.ancGridPadded {
    padding-top: 0;
}

.progress {
    margin-top: 40px;
    height: 80px;
}

.favorites__table {
    th, td {
        width: 14%;
    }
    th:first-child, td:first-child {
        width: auto;
    }
    th:last-child, td:last-child {
        max-width: 100px;
        text-align: right;
    }

    td.type-authors.field-posts {
        white-space: nowrap;
    }
}

.favorites__pagination {
  margin-top: 5px;
}

.favorites__table {
    .namePath.brackets {
        font-size: 0.9em;
        opacity: 0.8;
    }
}
