.admin__board {
  background: #e6eefa;
  box-sizing: border-box;
  margin: 20px auto 0;
  max-width: 100%;
  padding: 12px;
  width: 990px;

  header {
      display: block;
      background-color: #333;
      border-radius: 4px 4px 0 0;
      margin: -12px -12px 0;
      padding: 12px;
      color: #fff;

      .backLink {
          color: #fff;
          float: right;
          text-decoration: none;
          cursor: pointer;
      }
  }

  .admin__links {
      li {
          display: inline-block;
          margin: 8px 12px 0 0;
      }
  }

  h3 {
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #333;
  }

  .change__date {
      font: 16px/1.45 "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  }

  .last-news {
      margin-top: 12px;
      &.collapsed .text {
          display: none;
      }
      &.expanded .text {
          display: block;
      }

      button {
          border: none;
          background: transparent;
      }

      .posted {
          margin-left: 4px;
      }

      a {
          margin-right: 4px;
      }
  }

  .admin-nodes {
      h3 {
          font-weight: bold;
          margin-bottom: 12px;
          margin-top: 12px;
      }

      button {
          font-weight: bold;
          margin-top: 12px;
      }

      ol {
          list-style-type: decimal;
          padding-left: 20px;
      }

      li span {
          margin-left: 8px;
      }
  }

  .partner-name {
      margin-right: 4px;
      &::after {
          content: ': ';
      }
  }
}


.admin-tool-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
    padding-bottom: 10px;
}