.search {
  @include block-view;

  &--board {
    .ancBtn {
      margin: 0;
      margin-bottom: .5em;
      margin-left: 20px;
    }

    .search__filed-label {
      width: 100%;
    }
  }
}

.search__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.search__title {
  width: 100%;
  @include title-block;
}

.search__field-wrap {
  position: relative;
  width: 70%;
  display: flex;
  align-items: flex-end;

  &.invalid {
    .search__label-value {
      color: $err-color;
    }

    .search__inp  {
      border-color: $err-color;
      &:focus {
        border-color: $err-color;
      }
    }
  }

  &.valid {
    .search__inp  {
      border-color: $success-color;
    }

    &:focus {
      border-color:#000;
    }
  }
}

.search__label-value {
  margin-right: 7px;
}

.search__inp {
  position: relative;
  margin-top: 5px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  z-index: 11;
  border: 1px solid $input-border;
}

.search__err-msg {
  position: absolute;
  z-index: 10;
  background: #fff;
  top: 77%;
  width: 350px;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid $err-color;
  border-radius: 0 0 6px 6px;
  border-top: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: #c14139;
  padding: 5px 10px;
  color: $err-color;

  span {
    margin-left: 7px;
  }
}

.search__letters {
  margin-top: 10px;
}

.search__letters-title {
  margin: 0;
  margin-bottom: 10px;
  color: #181a1c;
  display: block;
  font-weight: bold;
  line-height: 1.26;
}

.search__letters-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 8px;

    &.search__row-1 {
        margin-left: 20px;
    }
    &.search__row-2 {
        margin-left: 56px;
    }
}

.search__letters-item {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 0px 2px;
    text-transform: capitalize;
  }

    &.search__active-letter a{
        font-weight: 700;
        background-color: #eff2d3;
        text-decoration: none;

        &:hover {
            background-color: #afbc22;
        }
    }
}

.search__subtitle__tip {
    display: block;
}

.search-boards__title {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 20px;
}