.page-boards {
  #main {
    color: $text-color;
  }

  .hidden {
    display: none;
  }

  .link {
    text-decoration: none;
  }

  button.link[disabled] {
    color: gray;
    text-decoration: none;
    cursor: default;
  }

  .brackets::before {
    margin-left: 2px;
    content: ' (';
  }

  .brackets::after {
    content: ') ';
  }

  .page .actions > * {
    margin: 4px 6px;
  }

  .empty > input,
  select.empty {
    border: 1px solid $input-border;
  }

  .filled > input,
  select.filled {
    border: 1px solid #090;
  }

  input[type="search"]:focus {
    border: 1px solid #888;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .progress-linear {
    height: 2px;
    background: rgba($link-color, .5);

    // rewrite progress lib rules
    .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Indeterminate {
      background: rgba($link-color, .5);
      width: 40%;
    }
    .MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
      display: none;
    } 
  }

}

.text-matches {
  background-color: #ffff96;
}

  // popup material lib
  .MuiDialog-root {
    &.full-width {
      .MuiDialog-paperWidthSm {
        max-width: unset;
        width: 100%;
      }
    }
  }
