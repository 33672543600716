.board-edit__field-label {
  padding-bottom: 2px;
  span {
    color: #181a1c;
    margin-right: 3px;
    font-weight: bold;
    line-height: 1.26;
  }
}

.board-edit__field {
  min-height: 150px;
}

.board-edit__btn-reset-wrap {
  margin-top: 5px;

  .btn {
    font-size: 16px;
  }
}

.board-edit__btns-control {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.board-edit__btn-submit {
  .btn--submit {
    transition: .4s ease;
    &:hover {
      background: $btn-submit-color-hover;
    }
  }
}

.board-edit__btn-cancel {
  margin: 0px 20px;

  .link--cancel:hover {
    text-decoration: underline;
  }
}

.board-edit__btn-preview {
  .btn--link {
    font-size: 16px;
    color: $link-color;

    &:hover {
      opacity: 1;
      color: $link-color-hover;
    }
  }
}