

.user-info__title {
  color: #181a1c;
  display: block;
  font-weight: bold;
  line-height: 1.26;
  margin-bottom: 2px;
}

.user-info__value {
  margin: 0;
}

.user-info__name {
  margin-right: 5px;
}