  .nav-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &--footer {
      margin-top: 24px;
      justify-content: center;
  
      li {
        &::after {
          content: '|';
          color: #c9c5b9;
          margin-left: 8px;
          margin-right: 8px;
        }
  
        &:last-child::after {
          content: '';
          margin: 0;
        }
      }
    }
  }

  .menu-nav__item {
    position: relative;
    display: inline-block;
    margin: 8px 12px 0 0;

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      opacity: .5;
      pointer-events: none;
      @include spinner-link-favorite;
    }
  }

  .menu-nav__item-btn {
    border: 0;
    background: 0;
  }

  .menu-nav__add-remove-btn {
    position: relative;
    border: 0;
    background: 0;
    color: $link-color;
    &:hover {
      color: $link-color-hover;
    }

    &::before {
      content: "\e60d";
    }
    &.active::before {
      content: "\e60b";
    }

  }

  

  .menu-nav__item-btn-link {
    display: inline-block;
    border: 0;
    background: 0;
    &:hover {
      text-decoration: underline;
      color: $link-color-hover;
    }
    &:focus {
      outline: 0;
    }
  }