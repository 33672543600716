
  .error-view {
    @include wrap-desktop;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    color: #181a1c;
    margin-top: 10px;
    padding: 20px 20px 20px 57px;
    position: relative;
    text-shadow: none;
    transform-origin: top;
    padding-left: 55px !important; //need for overide parent styles .page__section
  }

  .error-view::before {
    content: "\e668";
    font: normal 25px/0 "ancestry-icon";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    left: 20px;
    position: absolute;
    speak: none;
    text-transform: none;
    top: 32px;
    color: #ff8e00;
    box-sizing: inherit;
  }

  .error-view__content {
    display: flex;
    flex-direction: column;
    & > *:not(:first-child):not(:last-child){
      margin: 20px 0;
    }
  }

  .content-link__title {
    margin: 0 4px;
    text-decoration: underline;
  }
