.favorites-full {
  display: grid;
  grid-template-columns: 17% 1fr;
  grid-column-gap: 30px;
}

.favorites-full__content {

  .table--favorites-threads,
  .table--favorites-boards {
    & .table__row {
      grid-template-columns: 1fr 15% 25% 10%;
    }
  }
}

.favorites-full__menu-title {
  border-bottom: 1px dotted #c3be7f;
  padding-bottom: 4px;
}

.favorites-full__menu-item {
  color: #005c7d;
  padding: 4px;
  cursor: pointer;
  &.active {
    background: #afbc22;
    a {
      color: #fff;
    }
  }
}

.favorites-full__messages {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 6px;
  color: #181a1c;
}

.favorites-full__messages-text {
  position: relative;
  padding: 20px 20px 20px 57px;
  &::before {
    content: "\e63f";
    top: 50%;
    transform: translateY(-50%);
    color: #71b6ce;
    font: normal 25px/23px ancestry-icon;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    left: 20px;
    position: absolute;
    speak: none;
    text-transform: none;
    color: #71b6ce;
  }
}
