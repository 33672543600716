
.thread-tool {
  padding: 2px;
  opacity: .7;
  transition: .3s ease;
  text-shadow: 0px 0px 0px rgba(0,0,0, .0);
  &:hover {
    opacity: 1;
    text-shadow: 0px 3px 3px rgba(0,0,0, .2);

    & .thread-tool__item .icon {
      text-shadow: 0px 3px 3px rgba(0,0,0, .2);
    }
    & .thread-tool__admin span {
      text-shadow: 0px 3px 3px rgba(0,0,0, .2);
    }
  }
}

.thread-tool,
.thread-tool__list,
.thread-tool__admin {
  display: flex;
  align-items: center;
}

.thread-tool__item {
  margin-right: 7px;

  &:last-of-type {
    margin-right: 0;
  }
}

.thread-tool__admin {
  margin-left: 7px;
  span {
    margin-right: 3px;
  }
}

.thread-tool__item-btn {
  border: 0;
  background: 0;

  &.iconTrash {
    color: $link-color;

    &.threadDeleteAll {
      color: $dark-red;
    }
  }
}