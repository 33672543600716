.btn {
  padding: 5px 12px;
  background: $bck-btn;
  color: #fff;
  border: 1px solid transparent;
  line-height: 18.9px;
  border-radius: 3px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  transform: scale(0.999);

  &:active {
    opacity: .7;
  }

  &:hover {
    transition: .4s ease;
    opacity: .9;
  }

  &--disabled {
    opacity: .5;
    cursor: default;
    transition: .4s ease;
    text-decoration: none !important;
    &:hover {
      opacity: .5;
    }
  }

  &--search {
    margin: 0 8px 2px 8px;
    height: fit-content;
  }

  &--link {
    border: 0;
    background: 0;
    color: $link-color;
    font-weight: normal;
    padding: 0;
    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    &.advanced-search-link {
      font-size: 16px;
      padding: 1%;
      &:focus {
        outline: 2px solid #d37730;
        outline-offset: 0;
      }
    }
  }

}

button.anchor {
  color: #005c7d;
  background-color: transparent;
  border: none;
  &:hover {
    text-decoration: underline;
  }
}