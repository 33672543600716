.request__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-indent: 0;
  margin: 10px 0 0 10px;
  .request__list-item {
    list-style: inherit;
    margin-top: 5px;
    &::before {
      content: '\2022';
      display: block;
      font-weight: bold;
      height: 0;
      margin-left: -10px;
      opacity: .5;
    }
  }
}
