.page.page-boards {
  &--volunteer {
    min-height: 400px;
    .page__content {
      .page__section {
        .volunteer-content {
          background-color: transparent;
          padding: 20px 20px 20px 0;
          border: none;
          .volunteer__btns-wrap {
            margin-top: 20px;
            .volunteer__link {
              line-height: 43px;
              vertical-align: middle;
              margin-right: 12px;
            }
          }
          .volunteer__title:last-of-type {
            margin-top: 30px;
          }
          &--error {
            position: relative;
            .volunteer__title {
              padding-left: 37px;
            }
            &:before {
              content: '\e668';
              font: normal 25px/0 'ancestry-icon';
              left: 20px;
              position: absolute;
              speak: none;
              text-transform: none;
              top: 32px;
              color: #ff8e00;
            }
          }
          &--submitted {
            margin: 0;
            .volunteer__title:last-of-type {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

.volunteer__submit {
  margin-right: 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
  background-color: #e3720f;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  transition: padding-left 0.2s;
  vertical-align: middle;
  line-height: 21.9px;
  min-height: 40px;
  padding: 8px 14px 8px;

  &:hover {
    background-color: darken( #e3720f, 10% );
  }
}

.volunteer__title {
  span{
    margin-right: 4px;
  }
  &--bold {
    font-weight: bold;
    @include capitalize-first-letter;
  }
}

.volunteer__link {
  &--capitalized {
    @include capitalize-first-letter;
  }
}

.volunteer__list {
  list-style: none;
  margin: 10px 0 0 10px;
  .volunteer__list-item {
    margin-top: 5px;
    &::before {
      content: '\2022';
      display: block;
      font-weight: bold;
      height: 0;
      margin-left: -10px;
      opacity: .5;
    }
  }
}
