.page.node-options {
  .actions {
    margin-top: 16px;
    button {
      margin-right: 16px;
    }
  }

  &.category {
    ul {
      display: none;
    }
  }
}
