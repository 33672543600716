.form-filed {
  position: relative;

  &--checkbox {
    margin-bottom: 5px;

    .form-filed__label {
      position: relative;
      font-weight: normal;
      line-height: 21px;
      max-width: 100%;
      padding: 6px 0 0 24px;
      cursor: pointer;
    }

    .form-filed__inp-checkbox {
      display: none;

      &:checked~.form-filed__toggle {
        background-color: #b8d94c;
        background-image: linear-gradient(#b8d94c, #9cbe30);

        &::before {
          content: "\e656";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .form-filed__toggle {
      position: relative;
      background-color: #dedede;
      box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.2);
      color: #fff;
      content: '';
      float: left;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      margin: 1px 4px 0 -24px;
      text-align: center;
      text-indent: 0;
      width: 20px;
      border-radius: 3px;
      font-family: "ancestry-icon";
      font-weight: normal;
    }

    .checkbox {
      &:not(:checked)+label {
        &::before {
          content: "";
          border: 1px solid $input-border;
        }
      }
    }

    .checkbox {
      &:checked+label {
        &::before {
          background-color: #b8d94c;
          background-image: linear-gradient(#b8d94c, #9cbe30);
        }
      }
    }
  }
}


.form-filed__label-succes {
  &::after {
    color: #9cbe30;
    content: '\e656';
    font: 14px/0 "ancestry-icon";
    margin-left: 8px;
    position: static;
  }
}

.form-filed__title-error {
  color: #c14139;
  font-weight: normal;
  line-height: .9;
  margin-left: 8px;

  &::before {
    content: "\e668";
    display: inline-block;
    font-size: 13px;
    margin-right: 0;
    text-indent: 0;
    top: 0;
    width: 1.25em;
  }
}


.form-filed {
  &.error {
    .form-filed__title {
      color: $err-color;
    }

    input,
    textarea {
      border-color: $err-color;
    }
  }
}

.form-filed__inp,
.form-filed__inp-textarea {
  &:not(.readonly) {
    border-color: $border-field-enable;
  }
}