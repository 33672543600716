@mixin wrap-desktop {
  margin: 0 auto;
  max-width: 990px;
}

@mixin block-view {
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  background-color: #fff;
  padding: 20px;
}

@mixin title-page {
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  text-align: center;
}

@mixin title-block {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 10px;
}

@mixin label-inp {
  color: #181a1c;
  display: block;
  font-weight: bold;
  line-height: 1.26;
}

@mixin table-header-board  {
  border-bottom: 2px solid #c9c5b9;
}

@mixin table-footer-board {
  border-top: 1px solid #c9c5b9;
}

@mixin link-category {
  color: $link-color;
  line-height: 23.2px;

  &:hover {
    transition: .4s ease;
    opacity: .7;
  }

  &:active {
    opacity: .5;
  }
}

@mixin fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@mixin table-grid-2col {
  & .table__row {
    display: grid;
    grid-template-columns: 1fr 10%;
    padding: 2px 0px;
    & .table__col {
      width: 100%;
      &:last-of-type {
        text-align: right;
      }
      a {
        padding-left: 0;
      }
    }
  }
}

@mixin table-grid-3col {
  & .table__row {
    display: grid;
    grid-template-columns: 1fr 20% 10%;
    padding: 2px 0px;
    & .table__col {
      width: 100%;
      &:last-of-type {
        text-align: right;
      }
      a {
        padding-left: 0;
      }
    }
  }
}

@mixin table-grid-4col {
  & .table__row {
    display: grid;
    grid-template-columns: 1fr 15% 10% 20%;
    padding: 2px 0px;
    & .table__col {
      width: 100%;

      &:last-of-type {
        text-align: right;
      }
      a {
        padding-left: 0;
      }
    }
  }
}


@mixin spinner-link-favorite {
  .progress.progress--min-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
      width: 20px !important; // needed for overwrite inline style library
      height: 20px !important; // needed for overwrite inline style library
    }
  }
}

@mixin capitalize-first-letter {
  text-transform: lowercase;
  display: inline-block;
  &::first-letter {
    text-transform: uppercase;
  }
}
