.recycle-bin.page {
  .page-number {
    // Not actual till recycle bin endpoint will return pagination
    display: none;
    text-align: center;
    &.top {
      margin-bottom: 8px;
    }

    &.bottom {
      margin-top: 16px;
    }
  }

  th {
    white-space: nowrap;
  }

  td.actions {
    white-space: nowrap;
    min-width: 80px;
  }

  td.author {
    white-space: nowrap;
    min-width: 80px;
  }
}
