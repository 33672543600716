.r-location {
  display: flex;
  align-items: center;
}

.r-location__item {
  margin-right: 10px;
  &:last-of-type {
    margin-right: 0;
  }
}

.r-location__label {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.r-location__radio {
  display: none;

  &:checked ~ .r-location__btn {
    background: #6ba410;
    box-shadow: inset 0 2px 1px #b8d94c, inset 0 -2px 0 rgba(0,0,0,0.2), inset 0 0 0 6px #6ba410, inset 0 0 0 10px #fff;
  }
}

.r-location__btn {
  position: relative;
  background-color: #dedede;
  box-shadow: inset 0 -2px 0 rgba(0,0,0,0.2);
  color: #fff;
  content: '';
  float: left;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin: 1px 4px 0 -24px;
  text-align: center;
  text-indent: 0;
  width: 20px;
  border-radius: 50%;
}


.r-location__text {
  font-weight: normal;

  .r-location__text-name {
    margin-right: 3px;
  }

  .r-location__text-type {
    @include capitalize-first-letter;
  }
}