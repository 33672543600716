
.attachments__title {
  color: #181a1c;
  display: block;
  font-weight: bold;
  line-height: 1.26;
  margin-bottom: 5px;
} 

.attachments__list {
  display: flex;
  flex-wrap: wrap;
}

.attachments__field-wrap {
  margin-bottom: 10px;
}

.attachments__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 15px;
  transition: .3s ease;

  &:hover {
    .attachment__item-delete {
      opacity: 1;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }
}

.attachments__item-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.15);
}

.attachment__item-delete {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(0,0,0,0.6);
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  line-height: 17px;
  min-height: 20px;
  padding: 0 5px 1px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
  margin-top: 5px;
  opacity: .3;
  box-shadow: 0px 0px 0px rgba(0,0,0,0.15);
  transition: .3s ease;
}

.attachments__field-btn-add {
    background: rgba(0,0,0,0.6);
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: none;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: bold;
    line-height: 18.9px;
    min-height: 30px;
    padding: 5px 12px;
    text-align: center;
    text-indent: 0;
    -webkit-transition: .2s padding-left;
    transition: .2s padding-left;
    vertical-align: middle;
}


// attachments for edit board info page

.attachments {
  &--board-edit {
    margin-top: 20px;
    .attachments__list {
      display: flex;
      flex-wrap: wrap;
    }

    .attachments__size-error {
        margin-left: 5px;
        color: $err-color;
        font-size: 14px;
        transition: .4s ease;
    }

    .attachments__item {
      max-width: 150px;
      &:hover {
        .attachments__item-btn {
          box-shadow: 0px 1px 4px rgba(0,0,0, .3);
          transform: translateY(-10%) scale(.95);
          opacity: 1;
        }
      }
    }

    .attachments__item-btns {
      display: flex;
      flex-direction: column;
    }

    .attachments__btn {
      border: 0;
      background: 0;
      color: #181a1c;
      display: block;
      font-weight: bold;
      line-height: 1.26;
      margin-bottom: 20px;
      transition: .4s ease;
      transform: scale(.99);

      &:hover {
        opacity: .9;
        transform: scale(.97);
      }

      &:focus {
        outline: 0;
        opacity: .9;
        transform: scale(.99);
      }
    }

    .attachments__item-btn {
      margin-top: 5px;
      transform: scale(0.9);
      background: rgba(0,0,0,0.6);
      color: #fff;
      opacity: .5;
      transition: .4s ease;
      border: 1px solid transparent;
      border-radius: 3px;
      cursor: pointer;
      font-family: inherit;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-weight: bold;
      padding: 2px 5px;
      text-align: center;
      text-indent: 0;
    }

    .attachments__file-name {
      text-align: center;
      word-break: break-word;
    }
    .attachments__overlay {
      position: relative;
      &.active {

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.1);
          z-index: 10;
          border-radius: 5px;      
        }
      .progress {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      }
    }


  }
}

