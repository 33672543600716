.banner {
    margin-bottom: 20px;
    width: 100%;
    background-color: #CEDCCA !important;
    position: relative;
    padding: 15px 20px !important;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    @media screen and (max-width: 767px) {
        padding: 15px !important;

        .grid {
            flex-direction: column;
            align-items: center;
            gap: 15px;
        }

        .illustration {
            max-width: 170px;
            height: 80px;
        }

        .text-button {
            max-width: width;
            align-items: center;
        }

        .text {
            gap: 5px;
            text-align: center;
        }

        .heading {
            font-size: 18px;
        }
    }
}

.grid {
    gap: 20px;
    display: flex;
}

.illustration {
    width: 100%;
    max-width: 240px;
    height: auto;
    
    @media screen and (min-width: 768px) and (max-width: 849px) {
        margin: 9.5px 0px 9.5px 0px;
    }
}

.text {
    color: #262626;
    font-style: normal;
    display: flex;
    flex-direction: column;
}

.text-button {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.heading {
    font-size: 21.5px;
    font-weight: 600;
    line-height: 28.595px;
}

.intro {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 19.285px;
}

.ancBtn {
    width: fit-content;
}

.iconRemove {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #262626;
    font-family: ancestry-icon;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}