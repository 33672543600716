.search-boards__wrap {
  @include wrap-desktop;
  padding-top: 10px;
  padding-bottom: 20px;
}

.search-boards__title {
  @include title-page;
  margin-bottom: 40px;
}

.search-boards__content {
  @include block-view;
}

.search-boards__content-title {
  @include title-block;
}

.search-boards__list-title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
}

.search-boards__list {
  border-top: 2px solid rgb(201, 197, 185);
}

.search-boards__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px 8px;
  border-bottom: 1px solid rgb(236, 236, 236);

  &:last-of-type {
    border: 0;
  }
}


.search-boards__item-wrap {
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;

    .search-boards__item-link::after {
      display: none;
    }
  }
}

.search-boards__item-link {
  position: relative;
  display: block;


  &::after {
    content: '';
    position: absolute;
    display: block;
    right: -14px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 6px;
    border-top: 1px solid black;
    border-right: 1px solid black;
    transition: .2s ease-out;
  }
}

.search-boards__empty {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  color: #181a1c;
  margin-top: 10px;
  padding: 20px 20px 20px 57px;
  text-shadow: none;

  span {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 23px;
    color: #ff8e00;
  }
}