.page.board-info.edit {
  [type="submit"] {
    margin-left: 0;
  }

  .field-text {
    label {
      display: inline-block;
    }

    span {
      margin-right: 4px;
    }

    textarea {
      margin: 4px 0;
      min-height: 200px;
    }
  }

  .field-email {
    margin-top: 16px;
    input {
      width: 200px;
    }

    .insert-email {
      margin-left: 4px;
    }
  }

  .add-attachment {
    background-color: transparent;
    border: none;
    font-weight: 700;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .attachments > ul {
    display: flex;
    > li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 360px;
      border: 1px solid #ccc;
      padding: 8px;
      .actions {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        button {
          background-color: #888;
          border-radius: 4px;
          border: none;
          color: white;
          font-weight: 700;
          margin-bottom: 8px;
          padding: 2px 6px;
        }
      }
    }
  }
}

.boards-info-modal {
  &.loading {
    .MuiPaper-root {
      width: 300px;
    }
  }
  .MuiPaper-root {
    max-width: unset;
    width: 990px;
    transition: .4s ease;
    margin: 0;
    padding: 10px;
  }
  .progress--min-wrap {
    margin: 0;
  }

}
