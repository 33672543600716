.request__field-wrap{
  min-height: 1px;
  margin-top: 20px;
  .request__checkbox{
    height: 30px;
    line-height: 0;
    opacity: 0;// !important
    overflow: hidden;
    position: absolute;
    width: 1px;
    &:not([disabled])+.request__label{
      cursor: pointer;
    }
    &:not(:checked)+.request__label::before {
      color: rgba(0, 0, 0, 0);
      border: 1px solid $input-border;
    }
    &+.request__label::before{
      content: '';
      font-size: 14px;
      color: #fff;
      float: left;
      width: 20px;
      height: 20px;
      margin: 1px 4px 0 -24px;
      line-height: 20px;
      text-align: center;
      text-indent: 0;
      border-radius: 3px;
    }
    &:checked+.request__label::before{
      content: "\e656";
      font-family: "ancestry-icon";
      font-weight: normal;
      color: #fff;
      background-color: #b8d94c;
      background-image: linear-gradient(#b8d94c, #9cbe30);
    }
  }
  .request__label{
    display: inline-block;
    font-weight: normal;
    line-height: 21px;
    max-width: 100%;
    padding: 6px 0 0 24px;
    user-select: none;
    width: auto;
    &:not(.form__submit){
      color: #181a1c;
    }
  }
}
.request__field{
  input, textarea{
    border: 1px solid $input-border;
  }
}

.request__field-title{
  display: block;
  font-weight: bold;
  line-height: 1.26;
  &--error-message {
    color: #c14139;
    font-weight: normal;
    line-height: 0.9;
    margin-left: 8px;
  }
  &--error-message::before {
    content: "\e668";
    display: inline-block;
    font-size: 13px;
    margin-right: 0;
    text-indent: 0;
    top: 0;
    width: 1.25em;
  }
  &--success::after {
    color: #9cbe30;
    content: "\e656";
    font: 14px/0 "ancestry-icon";
    margin-left: 8px;
    position: static;
  }
  &--required::after {
    bottom: -10px;
    color: #c14139;
    content: "*";
    font-size: 2em;
    font-weight: bold;
    line-height: 0;
    position: relative;
  }
  &--error {
    color: #c14139;
  }
}

.request__title{
  &--error-message{
    color: #c14139;
    font-weight: normal;
    line-height: .9;
    margin-left: 8px;
  }
  &--error-message::before {
    content: "\e668";
    display: inline-block;
    font-size: 13px;
    margin-right: 0;
    text-indent: 0;
    top: 0;
    width: 1.25em;
  }
  &--success::after {
    color: #9cbe30;
    content: "\e656";
    font: 14px/0 "ancestry-icon";
    margin-left: 8px;
    position: static;
  }
  &--error {
    color: #c14139;
  }
  &--bold{
    font-weight: bold;
    line-height: 1.26;
  }
}

.request__input{
  &--error{
    border: 1px solid #c14139;
  }
}

.request__btns-wrap{
  margin-top: 20px;
}

.form__submit {
  margin-right: 5px;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  color: #fff !important;
  background-color: #e3720f;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.9px;
  min-height: 30px;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none !important;
  text-indent: 0;
  transition: padding-left 0.2s;
  vertical-align: middle;
  &:disabled {
    background-color: #a9a9a9;
    border-color: transparent !important;
    opacity: 0.6;
    outline: none !important;
    pointer-events: none;
    -webkit-text-fill-color: #fff;
  }
}

.form__link {
  margin-left: 5px;
}
