.favorites {}

.favorites__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorites__title {
  @include title-block;
}

.favorites__link-all {
  font-size: 16px;
  line-height: 17px;
  background: rgba(0,0,0,0.6);
  padding: 2px 5px;
  box-sizing: border-box;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0);
  transition: .2s ease;
  
  &:hover {
    color: rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
    text-shadow: 0px 0px 0px rgba(0,0,0,0.6);
    text-decoration: none;
  }
}


.favorites__modal {
  .MuiDialogContent-root {
    font-size: 18px;
  }
  .MuiDialogActions-root.MuiDialogActions-spacing {
    display: flex;
    justify-content: space-around;
  }
}