.print-thread {
  position: fixed;
  font-family: Arial, sans-serif;
  font-size: 14px;
  @include fill();
  background-color: white;
  color: black;
  z-index: 10000;
  padding: 24px;

  .body {
    border-top: 1px solid black;
    padding-left: 8px;
    margin-top: 16px;
    padding-top: 8px;
  }

  .field-view {
    display: block;
    .label {
      margin-right: 4px;
      &::after {
        content: ':';
      }
    }
  }

  .field-view.path,
  .field-view.url {
    margin-bottom: 16px;
    & > * {
      display: block;
    }
  }
}
