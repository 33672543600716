.widget.media-upload {
  > .widget-title {
    font-weight: bold;
  }

  .file-upload {
    > .active-upload .name {
      font-style: italic;
    }
  }
}
