.main {
  background: $bck-color;
}

.main__title {
  @include title-page;
}

.main__desc {
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
}
