.popup-error {
  color: red;
}

.MuiDialog-root.has-iframe .MuiDialogContent-root {
  position: relative;
  width: 720px;
  iframe {
    position: absolute;
    border: none;
    @include fill();
  }
}


	/* Possible global styles */
  .pageCrumbs:empty { display:none; }
  .con .conTitle > a { float:none; }
  .labelNoWrap { white-space:nowrap; word-wrap:normal; }
  .table { table-layout:fixed; }
  .table a { white-space:normal; word-break:break-word; }
  @media only screen and (max-width:1005px) {
    .table { table-layout:auto; }
  }
  input[readonly],
  textarea[readonly] { background:#f8f7f3; border-color:#cac1a2; cursor:default; -webkit-box-shadow:none; box-shadow:none; color:#9d9578; }
  .coloraltsand1 { color:#f5f5f5; }
  .coloraltsand2 { color:#ececec; }
  .coloraltsand3 { color:#dedede; }
  .coloraltsand4 { color:#a9a9a9; }
  .coloraltsand5 { color:#777777; }
  
  /* Global boards styles */
  p:empty { display:none; }
  .tempDeleteFix { position:relative; }
  .tempDeleteFix input[type="submit"] { cursor:pointer; height:20px; opacity:0; position:relative; width:22px; }
  .ie8 .tempDeleteFix input[type="submit"] { filter:alpha(opacity=0); }
  .ie8 .table { border-bottom:1px solid #d0c4a9; }
  .tempDeleteFix .icon { left:0; position:absolute; top:1px; }
  .boardsSidebarTitle { border-bottom:1px dotted #c3be7f; padding-bottom:4px; }
  .boardsList { margin-top:8px; }
  .alertFavoritesChanged { margin-bottom:12px; }
  /* @TODO: Change this to toggle class instead of style */
  .alertFavoritesChanged[style="display: none;"] + .topSpacing,
  .alertFavoritesChanged[style="display: none;"] + .alert,
  .alertFavoritesChanged[style="display: none;"] + p,
  .alertFavoritesChanged[style="display:none;"] + .topSpacing,
  .alertFavoritesChanged[style="display:none;"] + .alert,
  .alertFavoritesChanged[style="display:none;"] + p { margin-top:0; }
  .newBoardIndicator { margin-right:4px; }
  .boardsPageTools li { margin-top:5px; }
  .boardsPageTools li:first-child { margin-top:0; }
  
  /* Boards CMS Spots Styles */
  .boardsCmsHeader,
  .boardsCmsSidebar { margin-top:20px; text-align:center; }
  .boardsCmsHeader iframe { display:block; margin:0 auto; }
  @media only screen and (max-width:1005px) {
    .boardsCmsHeader { display:none; }
  }
  @media only screen and (max-width:767px) {
    .boardsCmsSidebar { display:none; }
  }
  
  /* Boards Footer Styles */
  .boardsFooter { margin:20px 8px -5px 8px; text-align:center; }
  .boardsFooter li { display:inline; }
  .boardsFooter li:before { content:''; display:inline-block; height:12px; border-left:1px solid #c9c5b9; margin-left:8px; padding-left:8px; position:relative; top:1px; }
  .boardsFooter li:first-child:before { display:none; }
  
  /* Default Page */
  #simpleSearch,
  #advancedSearch,
  #boardsFindForm { max-width:700px; }
  #boardsSpecificTopic #findBoardForm { border-bottom:0; border-bottom-left-radius:0; border-bottom-right-radius:0; }
  #boardsSpecificTopic #findBoardForm + .conBody { border-top:0; border-top-left-radius:0; border-top-right-radius:0; padding-top:10px; }
  #boardsSpecificTopic #findBoardForm + .conBody:empty { margin-top:-8px; }
  
  /* Simple & Advanced Search */
  #simpleSearchLink > .ancBtn { margin-right:12px; }
  #simpleSearchLink > a { display:inline-block; vertical-align:middle; }
  @media only screen and (max-width:479px) {
    #simpleSearchLink > .ancBtn,
    #simpleSearchLink > a { margin-top:0; }
  }
  .boardToSearch:empty { display:none; }
  .boardToSearch:empty + p { margin-top:0; }
  .boardToSearch .radio + label { padding-top:0; }
  #boardsAdvancedSearch .boardToSearch { padding-bottom:8px; }
  @media only screen and (max-width:1005px) {
    .boardToSearch .radio + label { margin:4px 0 0 0; }
  }
  
  /* favorites.aspx */
  #myFavoritesForm .alert { margin-top:0; }
  #myFavoritesForm td > a { display:inline-block; }
  .boardsList a { display:block; padding:4px; }
  .boardsList .boardsListActive a { background:#afbc22; color:#fff; }
  
  /* find.aspx */
  .findBoardsResults > .table { margin-top:0; }
  
  /* Surname alphabetical list */
  .boardsSurnamesList:empty { display:none; }
  .boardsSurnamesList:after { clear:both; content:''; display:table; }
  p ~ .boardsSurnamesList { margin-top:8px; }
  .boardsSurnamesList li { display:inline; float:left; }
  .boardsSurnamesList a { display:inline-block; height:28px; line-height:28px; padding:0 3px; text-align:center; min-width:20px; }
  .boardsSurnamesList a:hover { background-color:#afbc22; color:#fff; text-decoration:none; }
  .boardsSurnamesList .boardsSurnamesListSelected { background:#eff2d3; font-weight:bold; }
  #CatSection h2 { margin-bottom:-4px; }
  .boardsTableFooter { border-top:1px solid #c9c5b9; padding-top:4px; }
  #boardsSurnamesList2 { margin-left:28px; }
  #boardsSurnamesList3 { margin-left:56px; }
  
  /* surname.aspx */
  .noBoardsFound { display:block; }
  
  /* Favorites.aspx */
  .boardsFavoritesIcon:before { margin-right:0; }
  
  /* thread.aspx */
  .threadAuthor { margin-top:8px; }
  .threadAuthorFav { margin-left:4px; }
  .threadHeader + .boardThread .threadDate:first-child { margin-top:4px; }
  .threadLinks:empty { display:none; }
  .threadLinks { margin:12px 0 -8px 0; text-align:center; }
  .threadLinks ~ .threadLinks { margin-bottom:0; }
  .threadHeader { color:#555; float:right; }
  .threadLinks > a { float:none; }
  .boardThread > .alert { margin-bottom:12px; }
  .boardThread > .alert:only-child { margin-bottom:0; }
  .threadNav { padding-top:16px; text-align:right; }
  .threadNav li { display:inline-block; margin-left:8px; }
  .threadNav { opacity:.5; ;}
  .threadSort { margin-bottom:5px; }
  .ie8 .threadNav a { color:#aab588; }
  .boardThread:hover .threadNav { opacity:1; }
  .ie8 .boardThread:hover .threadNav a { color:#445708; }
  a.threadDeleteAll:before { color:#600; }
  .threadChangeView > a { float:left; }
  .threadChangeView:empty { display:none; }
  #messageList .threadChangeView > a { display:inline-block; line-height:28px; margin-right:12px; }
  #messageList .table { border-top:1px solid #c9c5b9; }
  #messageList .table tr:first-child td { padding-top:8px; }
  /* @TODO: Change this to toggle class instead of style */
  #alertDiv[style="display: block; "] + #messageList,
  #alertDiv[style="display: block;"] + #messageList { margin-top:12px; }
  .uLink img { vertical-align:text-bottom; }
  .uLink .nonUser { padding-left:4px; }
  .r a.uLink span { border-bottom:0; } /* @TODO: Temp override of community.css */
  .pipeSeparator { margin:0 8px; }
  .threadMeta { color:#555; line-height:1.8; text-align:right; }
  .unreadIndicator { margin-right:4px; }
  @media only screen and (max-width:767px) {
    .threadLinks { margin-bottom:-4px; }
  }
  @media only screen and (max-width:479px) {
    /* #messageList .table { font-size:12px; } */
    .threadMeta { text-align:left; }
    .threadHeader { float:none; padding-bottom:4px; }
  }
  .primaryThread { background:#fff; }
  .boardThread + .conSection { margin-top:8px; padding-top:8px; }
  .boardPerson { border-bottom:1px dotted #d0c4a9; margin-bottom:8px; padding-bottom:8px; }
  .secondaryThread > .alert { margin-bottom:8px; }
  .threadAttachments .photo { margin:0 8px 2px 0; }
  .threadAttachments .photo > img { max-height:none; }
  .threadAttachments td.bold { padding-bottom:4px; }
  @media only screen and (max-width:479px) {
    .threadAttachments td { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; display:block; float:left; padding-bottom:8px; width:50%; }
    .threadAttachments:after { content:''; clear:both; display:table; }
  }
  .postAttachment { margin-top:4px; }
  .postAttachment + .photo { margin-top:20px; }
  .threadPost a { text-decoration:underline; word-break:break-word; }
  #closedDiv { margin:4px 0 12px 0; }
  
  /* Create Post Page */
  #m_originalPanel .boardThread { margin-top:8px; }
  #m_originalPanel .boardThread:first-child { margin-top:0; }
  #originalMessageTitle { font-size:13px; font-weight:bold; }
  .createPostForm:first-child,
  .createPostForm.noDisplay:first-child + #previewPanel { border-top:none; margin-top:0; padding-top:0; }
  .createPostForm #m_message { height:160px; }
  
  /* Thread Pagination */
  .boardsPagination + .table { border-top:1px solid #ccc; }
  table + .boardsPagination { border-top:1px solid #c9c5b9; line-height:32px; padding-top:4px; }
  .boardsPagination:empty { display:none; }
  
  /* TreeView.ascx */
  @media only screen and (max-width:639px) {
    .treeViewLink { padding-left:0 !important; }
  }
  
  /* FlatView.ascx */
  .threadFlatView { table-layout:fixed; }
  .threadFlatView + .boardsPagination { border-top:0; }
  .threadFlatView { clear:both; width:100%; }
  .threadFlatView .boardThread { border:1px solid #d0c4a9; border-radius:3px; margin-top:8px; padding:8px; }
  #formSort { display:inline-block; min-width:115px; width:auto; }
  .threadFlatView .boardPerson { background:#eee; border-radius:3px 3px 0 0; margin:-8px -8px 8px; padding:8px; }
  
  /* Tools for categories and boards */
  .adminTools li,
  .boardsTools li { display:inline-block; margin:0 12px 8px 0; }
  @media only screen and (min-width:320px) {
    .conBody.adminTools li { margin-bottom:0; }
  }
  
  /* Temp table styles until HTML can be fixed */
  .table .dataGridHeader { background-color:transparent; }
  .table .dataGridHeader th,
  .table .dataGridHeader td,
  .table td.dataGridHeader { border-bottom:2px solid #c9c5b9; color:#333; font-weight:bold; text-align:left; word-break:normal }
  .table tbody .boardsToolsColumn { text-align:right; width:120px; }
  .table tbody tr.threadCurrent { background-color:#ffc; }
  @media only screen and (max-width:767px) {
    .homeFavoritesContainer .table .boardsToolsColumn { width:auto; }
  }
  
  /* Search Results Page */
  .boardsSearchResultPostTitle { float:left; }
  .boardsSearchResultPostDate { float:right; }
  #resultspane .table td { border-top:1px solid #c9c5b9; padding-bottom:16px; padding-top:16px; }
  #resultspane .table tr:first-child td { border-top:0; }
  @media only screen and (max-width:767px) {
    .boardsSearchResultPostTitle,
    .boardsSearchResultPostDate { float:none; }
  }
  
  /* Author Posts Page */
  .authorPosts .ancGrid + .conSection { margin-top:12px; }
  
  /* Admin Section */
  .boardsAdmin { background:#e6eefa; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; margin:20px auto 0 auto; max-width:100%; padding:12px; width:990px; }
  .boardsAdminHeader { background:#333; border-radius:4px 4px 0 0; margin:-12px -12px 0 -12px; padding:12px; color:#fff; }
  .boardsAdminHeader > a { color:#fff; float:right; }
  @media only screen and (max-width:479px) {
    .boardsAdminHeader > a { float:none; display:block; margin-bottom:8px; }
  }
  .boardsAdminLinks { padding-bottom:12px; }
  .boardsAdminLinks li { display:inline-block; margin:8px 12px 0 0; }
  .boardAdminNotification { margin:12px 0 -8px; text-align:center; }
  .boardObjection { padding:10px; word-wrap:normal; word-break:break-word; }
  
  /* Admin edit message page */
  .editMessageAuthorInfo { line-height:28px; }
  #breadCrumb + #catList { margin-top:12px; }
  .editBoardAttachment { border:1px solid #bbb0a3; display:inline-block; margin-right:12px; padding:8px; text-align:center; }
  .editBoardAttachment + .editBoardAttachment,
  .editBoardAttachment .postAttachment > div { margin-top:8px; }
  
  /* Report Abuse Page */
  #reportAbuseForm { max-width:600px; }
  
  /* Report Manage Spam */
  #dg_messages { word-break:break-word; }
  #dg_messages a { word-break:normal; }
  .yui-panel-container { max-width:95%; overflow:hidden; }
  
  /* Admin Delete Board */
  #deleteBoardsForm .alert { margin-top:0; }
  
  /* Admin Delete Message */
  #deleteMessageForm p strong { display:inline-block; width:100px; }
  
  /* Admin Edit Board Info Page */
  .editBoardInfoPage .mbformtext { min-height:200px; }
  
  /* Boards Popup Pages */
  .boardsPopup { padding:12px; }
  .boardsPopup :target { -webkit-animation:myTarget 5s; animation:myTarget 5s; }
  @-webkit-keyframes myTarget {
    10%  { background-color: transparent; }
    20%  { background-color: #ffff96; }
    80%  { background-color: #ffff96; }
  }
  @keyframes myTarget {
    10%  { background-color: transparent; }
    20%  { background-color: #ffff96; }
    80%  { background-color: #ffff96; }
  }
  .boardsPopup li a { text-decoration:underline; }
  .boardsPopup li a.bold { text-decoration:none; }
  .boardsPopup p.bold { margin-top:20px; }
  .boardsPopup h2 + p.bold { margin-top:8px; }


  	/* Possible global styles */
.pageCrumbs:empty { display:none; }
.con .conTitle > a { float:none; }
.labelNoWrap { white-space:nowrap; word-wrap:normal; }
.table { table-layout:fixed; }
.table a { white-space:normal; word-break:break-word; }
@media only screen and (max-width:1005px) {
	.table { table-layout:auto; }
}
input[readonly],
textarea[readonly] { background:#f8f7f3; border-color:#cac1a2; cursor:default; -webkit-box-shadow:none; box-shadow:none; color:#9d9578; }
.coloraltsand1 { color:#f5f5f5; }
.coloraltsand2 { color:#ececec; }
.coloraltsand3 { color:#dedede; }
.coloraltsand4 { color:#a9a9a9; }
.coloraltsand5 { color:#777777; }

/* Global boards styles */
p:empty { display:none; }
.tempDeleteFix { position:relative; }
.tempDeleteFix input[type="submit"] { cursor:pointer; height:20px; opacity:0; position:relative; width:22px; }
.ie8 .tempDeleteFix input[type="submit"] { filter:alpha(opacity=0); }
.ie8 .table { border-bottom:1px solid #d0c4a9; }
.tempDeleteFix .icon { left:0; position:absolute; top:1px; }
.boardsSidebarTitle { border-bottom:1px dotted #c3be7f; padding-bottom:4px; }
.boardsList { margin-top:8px; }
.alertFavoritesChanged { margin-bottom:12px; }
/* @TODO: Change this to toggle class instead of style */
.alertFavoritesChanged[style="display: none;"] + .topSpacing,
.alertFavoritesChanged[style="display: none;"] + .alert,
.alertFavoritesChanged[style="display: none;"] + p,
.alertFavoritesChanged[style="display:none;"] + .topSpacing,
.alertFavoritesChanged[style="display:none;"] + .alert,
.alertFavoritesChanged[style="display:none;"] + p { margin-top:0; }
.newBoardIndicator { margin-right:4px; }
.boardsPageTools li { margin-top:5px; }
.boardsPageTools li:first-child { margin-top:0; }

/* Boards CMS Spots Styles */
.boardsCmsHeader,
.boardsCmsSidebar { margin-top:20px; text-align:center; }
.boardsCmsHeader iframe { display:block; margin:0 auto; }
@media only screen and (max-width:1005px) {
	.boardsCmsHeader { display:none; }
}
@media only screen and (max-width:767px) {
	.boardsCmsSidebar { display:none; }
}

/* Boards Footer Styles */
.boardsFooter { margin:20px 8px -5px 8px; text-align:center; }
.boardsFooter li { display:inline; }
.boardsFooter li:before { content:''; display:inline-block; height:12px; border-left:1px solid #c9c5b9; margin-left:8px; padding-left:8px; position:relative; top:1px; }
.boardsFooter li:first-child:before { display:none; }

/* Default Page */
#simpleSearch,
#advancedSearch,
#boardsFindForm { max-width:700px; }
#boardsSpecificTopic #findBoardForm { border-bottom:0; border-bottom-left-radius:0; border-bottom-right-radius:0; }
#boardsSpecificTopic #findBoardForm + .conBody { border-top:0; border-top-left-radius:0; border-top-right-radius:0; padding-top:10px; }
#boardsSpecificTopic #findBoardForm + .conBody:empty { margin-top:-8px; }

/* Simple & Advanced Search */
#simpleSearchLink > .ancBtn { margin-right:12px; }
#simpleSearchLink > a { display:inline-block; vertical-align:middle; }
@media only screen and (max-width:479px) {
	#simpleSearchLink > .ancBtn,
	#simpleSearchLink > a { margin-top:0; }
}
.boardToSearch:empty { display:none; }
.boardToSearch:empty + p { margin-top:0; }
.boardToSearch .radio + label { padding-top:0; }
#boardsAdvancedSearch .boardToSearch { padding-bottom:8px; }
@media only screen and (max-width:1005px) {
	.boardToSearch .radio + label { margin:4px 0 0 0; }
}

/* favorites.aspx */
#myFavoritesForm .alert { margin-top:0; }
#myFavoritesForm td > a { display:inline-block; }
.boardsList a { display:block; padding:4px; }
.boardsList .boardsListActive a { background:#afbc22; color:#fff; }

/* find.aspx */
.findBoardsResults > .table { margin-top:0; }

/* Surname alphabetical list */
.boardsSurnamesList:empty { display:none; }
.boardsSurnamesList:after { clear:both; content:''; display:table; }
p ~ .boardsSurnamesList { margin-top:8px; }
.boardsSurnamesList li { display:inline; float:left; }
.boardsSurnamesList a { display:inline-block; height:28px; line-height:28px; padding:0 3px; text-align:center; min-width:20px; }
.boardsSurnamesList a:hover { background-color:#afbc22; color:#fff; text-decoration:none; }
.boardsSurnamesList .boardsSurnamesListSelected { background:#eff2d3; font-weight:bold; }
#CatSection h2 { margin-bottom:-4px; }
.boardsTableFooter { border-top:1px solid #c9c5b9; padding-top:4px; }
#boardsSurnamesList2 { margin-left:28px; }
#boardsSurnamesList3 { margin-left:56px; }

/* surname.aspx */
.noBoardsFound { display:block; }

/* Favorites.aspx */
.boardsFavoritesIcon:before { margin-right:0; }

/* thread.aspx */
.threadAuthor { margin-top:8px; }
.threadAuthorFav { margin-left:4px; }
.threadHeader + .boardThread .threadDate:first-child { margin-top:4px; }
.threadLinks:empty { display:none; }
.threadLinks { margin:12px 0 -8px 0; text-align:center; }
.threadLinks ~ .threadLinks { margin-bottom:0; }
.threadHeader { color:#555; float:right; }
.threadLinks > a { float:none; }
.boardThread > .alert { margin-bottom:12px; }
.boardThread > .alert:only-child { margin-bottom:0; }
.threadNav { padding-top:16px; text-align:right; }
.threadNav li { display:inline-block; margin-left:8px; }
.threadNav { opacity:.5; ;}
.threadSort { margin-bottom:5px; }
.ie8 .threadNav a { color:#aab588; }
.boardThread:hover .threadNav { opacity:1; }
.ie8 .boardThread:hover .threadNav a { color:#445708; }
a.threadDeleteAll:before { color:#600; }
.threadChangeView > a { float:left; }
.threadChangeView:empty { display:none; }
#messageList .threadChangeView > a { display:inline-block; line-height:28px; margin-right:12px; }
#messageList .table { border-top:1px solid #c9c5b9; }
#messageList .table tr:first-child td { padding-top:8px; }
/* @TODO: Change this to toggle class instead of style */
#alertDiv[style="display: block; "] + #messageList,
#alertDiv[style="display: block;"] + #messageList { margin-top:12px; }
.uLink img { vertical-align:text-bottom; }
.uLink .nonUser { padding-left:4px; }
.r a.uLink span { border-bottom:0; } /* @TODO: Temp override of community.css */
.pipeSeparator { margin:0 8px; }
.threadMeta { color:#555; line-height:1.8; text-align:right; }
.unreadIndicator { margin-right:4px; }
@media only screen and (max-width:767px) {
	.threadLinks { margin-bottom:-4px; }
}
@media only screen and (max-width:479px) {
	/* #messageList .table { font-size:12px; } */
	.threadMeta { text-align:left; }
	.threadHeader { float:none; padding-bottom:4px; }
}
.primaryThread { background:#fff; }
.boardThread + .conSection { margin-top:8px; padding-top:8px; }
.boardPerson { border-bottom:1px dotted #d0c4a9; margin-bottom:8px; padding-bottom:8px; }
.secondaryThread > .alert { margin-bottom:8px; }
.threadAttachments .photo { margin:0 8px 2px 0; }
.threadAttachments .photo > img { max-height:none; }
.threadAttachments td.bold { padding-bottom:4px; }
@media only screen and (max-width:479px) {
	.threadAttachments td { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; display:block; float:left; padding-bottom:8px; width:50%; }
	.threadAttachments:after { content:''; clear:both; display:table; }
}
.postAttachment { margin-top:4px; }
.postAttachment + .photo { margin-top:20px; }
.threadPost a { text-decoration:underline; word-break:break-word; }
#closedDiv { margin:4px 0 12px 0; }

/* Create Post Page */
#m_originalPanel .boardThread { margin-top:8px; }
#m_originalPanel .boardThread:first-child { margin-top:0; }
#originalMessageTitle { font-size:13px; font-weight:bold; }
.createPostForm:first-child,
.createPostForm.noDisplay:first-child + #previewPanel { border-top:none; margin-top:0; padding-top:0; }
.createPostForm #m_message { height:160px; }

/* Thread Pagination */
.boardsPagination + .table { border-top:1px solid #ccc; }
table + .boardsPagination { border-top:1px solid #c9c5b9; line-height:32px; padding-top:4px; }
.boardsPagination:empty { display:none; }

/* TreeView.ascx */
@media only screen and (max-width:639px) {
	.treeViewLink { padding-left:0 !important; }
}

/* FlatView.ascx */
.threadFlatView { table-layout:fixed; }
.threadFlatView + .boardsPagination { border-top:0; }
.threadFlatView { clear:both; width:100%; }
.threadFlatView .boardThread { border:1px solid #d0c4a9; border-radius:3px; margin-top:8px; padding:8px; }
#formSort { display:inline-block; min-width:115px; width:auto; }
.threadFlatView .boardPerson { background:#eee; border-radius:3px 3px 0 0; margin:-8px -8px 8px; padding:8px; }

/* Tools for categories and boards */
.adminTools li,
.boardsTools li { display:inline-block; margin:0 12px 8px 0; }
@media only screen and (min-width:320px) {
	.conBody.adminTools li { margin-bottom:0; }
}

/* Temp table styles until HTML can be fixed */
.table .dataGridHeader { background-color:transparent; }
.table .dataGridHeader th,
.table .dataGridHeader td,
.table td.dataGridHeader { border-bottom:2px solid #c9c5b9; color:#333; font-weight:bold; text-align:left; word-break:normal }
.table tbody .boardsToolsColumn { text-align:right; width:120px; }
.table tbody tr.threadCurrent { background-color:#ffc; }
@media only screen and (max-width:767px) {
	.homeFavoritesContainer .table .boardsToolsColumn { width:auto; }
}

/* Search Results Page */
.boardsSearchResultPostTitle { float:left; }
.boardsSearchResultPostDate { float:right; }
#resultspane .table td { border-top:1px solid #c9c5b9; padding-bottom:16px; padding-top:16px; }
#resultspane .table tr:first-child td { border-top:0; }
@media only screen and (max-width:767px) {
	.boardsSearchResultPostTitle,
	.boardsSearchResultPostDate { float:none; }
}

/* Author Posts Page */
.authorPosts .ancGrid + .conSection { margin-top:12px; }

/* Admin Section */
.boardsAdmin { background:#e6eefa; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; margin:20px auto 0 auto; max-width:100%; padding:12px; width:990px; }
.boardsAdminHeader { background:#333; border-radius:4px 4px 0 0; margin:-12px -12px 0 -12px; padding:12px; color:#fff; }
.boardsAdminHeader > a { color:#fff; float:right; }
@media only screen and (max-width:479px) {
	.boardsAdminHeader > a { float:none; display:block; margin-bottom:8px; }
}
.boardsAdminLinks { padding-bottom:12px; }
.boardsAdminLinks li { display:inline-block; margin:8px 12px 0 0; }
.boardAdminNotification { margin:12px 0 -8px; text-align:center; }
.boardObjection { padding:10px; word-wrap:normal; word-break:break-word; }

/* Admin edit message page */
.editMessageAuthorInfo { line-height:28px; }
#breadCrumb + #catList { margin-top:12px; }
.editBoardAttachment { border:1px solid #bbb0a3; display:inline-block; margin-right:12px; padding:8px; text-align:center; }
.editBoardAttachment + .editBoardAttachment,
.editBoardAttachment .postAttachment > div { margin-top:8px; }

/* Report Abuse Page */
#reportAbuseForm { max-width:600px; }

/* Report Manage Spam */
#dg_messages { word-break:break-word; }
#dg_messages a { word-break:normal; }
.yui-panel-container { max-width:95%; overflow:hidden; }

/* Admin Delete Board */
#deleteBoardsForm .alert { margin-top:0; }

/* Admin Delete Message */
#deleteMessageForm p strong { display:inline-block; width:100px; }

/* Admin Edit Board Info Page */
.editBoardInfoPage .mbformtext { min-height:200px; }

/* Boards Popup Pages */
.boardsPopup { padding:12px; }
.boardsPopup :target { -webkit-animation:myTarget 5s; animation:myTarget 5s; }
@-webkit-keyframes myTarget {
	10%  { background-color: transparent; }
	20%  { background-color: #ffff96; }
	80%  { background-color: #ffff96; }
}
@keyframes myTarget {
	10%  { background-color: transparent; }
	20%  { background-color: #ffff96; }
	80%  { background-color: #ffff96; }
}
.boardsPopup li a { text-decoration:underline; }
.boardsPopup li a.bold { text-decoration:none; }
.boardsPopup p.bold { margin-top:20px; }
.boardsPopup h2 + p.bold { margin-top:8px; }
	/* Possible global styles */
  .pageCrumbs:empty { display:none; }
  .con .conTitle > a { float:none; }
  .labelNoWrap { white-space:nowrap; word-wrap:normal; }
  .table { table-layout:fixed; }
  .table a { white-space:normal; word-break:break-word; }
  @media only screen and (max-width:1005px) {
    .table { table-layout:auto; }
  }
  input[readonly],
  textarea[readonly] { background:#f8f7f3; border-color:#cac1a2; cursor:default; -webkit-box-shadow:none; box-shadow:none; color:#9d9578; }
  .coloraltsand1 { color:#f5f5f5; }
  .coloraltsand2 { color:#ececec; }
  .coloraltsand3 { color:#dedede; }
  .coloraltsand4 { color:#a9a9a9; }
  .coloraltsand5 { color:#777777; }
  
  /* Global boards styles */
  p:empty { display:none; }
  .tempDeleteFix { position:relative; }
  .tempDeleteFix input[type="submit"] { cursor:pointer; height:20px; opacity:0; position:relative; width:22px; }
  .ie8 .tempDeleteFix input[type="submit"] { filter:alpha(opacity=0); }
  .ie8 .table { border-bottom:1px solid #d0c4a9; }
  .tempDeleteFix .icon { left:0; position:absolute; top:1px; }
  .boardsSidebarTitle { border-bottom:1px dotted #c3be7f; padding-bottom:4px; }
  .boardsList { margin-top:8px; }
  .alertFavoritesChanged { margin-bottom:12px; }
  /* @TODO: Change this to toggle class instead of style */
  .alertFavoritesChanged[style="display: none;"] + .topSpacing,
  .alertFavoritesChanged[style="display: none;"] + .alert,
  .alertFavoritesChanged[style="display: none;"] + p,
  .alertFavoritesChanged[style="display:none;"] + .topSpacing,
  .alertFavoritesChanged[style="display:none;"] + .alert,
  .alertFavoritesChanged[style="display:none;"] + p { margin-top:0; }
  .newBoardIndicator { margin-right:4px; }
  .boardsPageTools li { margin-top:5px; }
  .boardsPageTools li:first-child { margin-top:0; }
  
  /* Boards CMS Spots Styles */
  .boardsCmsHeader,
  .boardsCmsSidebar { margin-top:20px; text-align:center; }
  .boardsCmsHeader iframe { display:block; margin:0 auto; }
  @media only screen and (max-width:1005px) {
    .boardsCmsHeader { display:none; }
  }
  @media only screen and (max-width:767px) {
    .boardsCmsSidebar { display:none; }
  }
  
  /* Boards Footer Styles */
  .boardsFooter { margin:20px 8px -5px 8px; text-align:center; }
  .boardsFooter li { display:inline; }
  .boardsFooter li:before { content:''; display:inline-block; height:12px; border-left:1px solid #c9c5b9; margin-left:8px; padding-left:8px; position:relative; top:1px; }
  .boardsFooter li:first-child:before { display:none; }
  
  /* Default Page */
  #simpleSearch,
  #advancedSearch,
  #boardsFindForm { max-width:700px; }
  #boardsSpecificTopic #findBoardForm { border-bottom:0; border-bottom-left-radius:0; border-bottom-right-radius:0; }
  #boardsSpecificTopic #findBoardForm + .conBody { border-top:0; border-top-left-radius:0; border-top-right-radius:0; padding-top:10px; }
  #boardsSpecificTopic #findBoardForm + .conBody:empty { margin-top:-8px; }
  
  /* Simple & Advanced Search */
  #simpleSearchLink > .ancBtn { margin-right:12px; }
  #simpleSearchLink > a { display:inline-block; vertical-align:middle; }
  @media only screen and (max-width:479px) {
    #simpleSearchLink > .ancBtn,
    #simpleSearchLink > a { margin-top:0; }
  }
  .boardToSearch:empty { display:none; }
  .boardToSearch:empty + p { margin-top:0; }
  .boardToSearch .radio + label { padding-top:0; }
  #boardsAdvancedSearch .boardToSearch { padding-bottom:8px; }
  @media only screen and (max-width:1005px) {
    .boardToSearch .radio + label { margin:4px 0 0 0; }
  }
  
  /* favorites.aspx */
  #myFavoritesForm .alert { margin-top:0; }
  #myFavoritesForm td > a { display:inline-block; }
  .boardsList a { display:block; padding:4px; }
  .boardsList .boardsListActive a { background:#afbc22; color:#fff; }
  
  /* find.aspx */
  .findBoardsResults > .table { margin-top:0; }
  
  /* Surname alphabetical list */
  .boardsSurnamesList:empty { display:none; }
  .boardsSurnamesList:after { clear:both; content:''; display:table; }
  p ~ .boardsSurnamesList { margin-top:8px; }
  .boardsSurnamesList li { display:inline; float:left; }
  .boardsSurnamesList a { display:inline-block; height:28px; line-height:28px; padding:0 3px; text-align:center; min-width:20px; }
  .boardsSurnamesList a:hover { background-color:#afbc22; color:#fff; text-decoration:none; }
  .boardsSurnamesList .boardsSurnamesListSelected { background:#eff2d3; font-weight:bold; }
  #CatSection h2 { margin-bottom:-4px; }
  .boardsTableFooter { border-top:1px solid #c9c5b9; padding-top:4px; }
  #boardsSurnamesList2 { margin-left:28px; }
  #boardsSurnamesList3 { margin-left:56px; }
  
  /* surname.aspx */
  .noBoardsFound { display:block; }
  
  /* Favorites.aspx */
  .boardsFavoritesIcon:before { margin-right:0; }
  
  /* thread.aspx */
  .threadAuthor { margin-top:8px; }
  .threadAuthorFav { margin-left:4px; }
  .threadHeader + .boardThread .threadDate:first-child { margin-top:4px; }
  .threadLinks:empty { display:none; }
  .threadLinks { margin:12px 0 -8px 0; text-align:center; }
  .threadLinks ~ .threadLinks { margin-bottom:0; }
  .threadHeader { color:#555; float:right; }
  .threadLinks > a { float:none; }
  .boardThread > .alert { margin-bottom:12px; }
  .boardThread > .alert:only-child { margin-bottom:0; }
  .threadNav { padding-top:16px; text-align:right; }
  .threadNav li { display:inline-block; margin-left:8px; }
  .threadNav { opacity:.5; ;}
  .threadSort { margin-bottom:5px; }
  .ie8 .threadNav a { color:#aab588; }
  .boardThread:hover .threadNav { opacity:1; }
  .ie8 .boardThread:hover .threadNav a { color:#445708; }
  a.threadDeleteAll:before { color:#600; }
  .threadChangeView > a { float:left; }
  .threadChangeView:empty { display:none; }
  #messageList .threadChangeView > a { display:inline-block; line-height:28px; margin-right:12px; }
  #messageList .table { border-top:1px solid #c9c5b9; }
  #messageList .table tr:first-child td { padding-top:8px; }
  /* @TODO: Change this to toggle class instead of style */
  #alertDiv[style="display: block; "] + #messageList,
  #alertDiv[style="display: block;"] + #messageList { margin-top:12px; }
  .uLink img { vertical-align:text-bottom; }
  .uLink .nonUser { padding-left:4px; }
  .r a.uLink span { border-bottom:0; } /* @TODO: Temp override of community.css */
  .pipeSeparator { margin:0 8px; }
  .threadMeta { color:#555; line-height:1.8; text-align:right; }
  .unreadIndicator { margin-right:4px; }
  @media only screen and (max-width:767px) {
    .threadLinks { margin-bottom:-4px; }
  }
  @media only screen and (max-width:479px) {
    /* #messageList .table { font-size:12px; } */
    .threadMeta { text-align:left; }
    .threadHeader { float:none; padding-bottom:4px; }
  }
  .primaryThread { background:#fff; }
  .boardThread + .conSection { margin-top:8px; padding-top:8px; }
  .boardPerson { border-bottom:1px dotted #d0c4a9; margin-bottom:8px; padding-bottom:8px; }
  .secondaryThread > .alert { margin-bottom:8px; }
  .threadAttachments .photo { margin:0 8px 2px 0; }
  .threadAttachments .photo > img { max-height:none; }
  .threadAttachments td.bold { padding-bottom:4px; }
  @media only screen and (max-width:479px) {
    .threadAttachments td { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; display:block; float:left; padding-bottom:8px; width:50%; }
    .threadAttachments:after { content:''; clear:both; display:table; }
  }
  .postAttachment { margin-top:4px; }
  .postAttachment + .photo { margin-top:20px; }
  .threadPost a { text-decoration:underline; word-break:break-word; }
  #closedDiv { margin:4px 0 12px 0; }
  
  /* Create Post Page */
  #m_originalPanel .boardThread { margin-top:8px; }
  #m_originalPanel .boardThread:first-child { margin-top:0; }
  #originalMessageTitle { font-size:13px; font-weight:bold; }
  .createPostForm:first-child,
  .createPostForm.noDisplay:first-child + #previewPanel { border-top:none; margin-top:0; padding-top:0; }
  .createPostForm #m_message { height:160px; }
  
  /* Thread Pagination */
  .boardsPagination + .table { border-top:1px solid #ccc; }
  table + .boardsPagination { border-top:1px solid #c9c5b9; line-height:32px; padding-top:4px; }
  .boardsPagination:empty { display:none; }
  
  /* TreeView.ascx */
  @media only screen and (max-width:639px) {
    .treeViewLink { padding-left:0 !important; }
  }
  
  /* FlatView.ascx */
  .threadFlatView { table-layout:fixed; }
  .threadFlatView + .boardsPagination { border-top:0; }
  .threadFlatView { clear:both; width:100%; }
  .threadFlatView .boardThread { border:1px solid #d0c4a9; border-radius:3px; margin-top:8px; padding:8px; }
  #formSort { display:inline-block; min-width:115px; width:auto; }
  .threadFlatView .boardPerson { background:#eee; border-radius:3px 3px 0 0; margin:-8px -8px 8px; padding:8px; }
  
  /* Tools for categories and boards */
  .adminTools li,
  .boardsTools li { display:inline-block; margin:0 12px 8px 0; }
  @media only screen and (min-width:320px) {
    .conBody.adminTools li { margin-bottom:0; }
  }
  
  /* Temp table styles until HTML can be fixed */
  .table .dataGridHeader { background-color:transparent; }
  .table .dataGridHeader th,
  .table .dataGridHeader td,
  .table td.dataGridHeader { border-bottom:2px solid #c9c5b9; color:#333; font-weight:bold; text-align:left; word-break:normal }
  .table tbody .boardsToolsColumn { text-align:right; width:120px; }
  .table tbody tr.threadCurrent { background-color:#ffc; }
  @media only screen and (max-width:767px) {
    .homeFavoritesContainer .table .boardsToolsColumn { width:auto; }
  }
  
  /* Search Results Page */
  .boardsSearchResultPostTitle { float:left; }
  .boardsSearchResultPostDate { float:right; }
  #resultspane .table td { border-top:1px solid #c9c5b9; padding-bottom:16px; padding-top:16px; }
  #resultspane .table tr:first-child td { border-top:0; }
  @media only screen and (max-width:767px) {
    .boardsSearchResultPostTitle,
    .boardsSearchResultPostDate { float:none; }
  }
  
  /* Author Posts Page */
  .authorPosts .ancGrid + .conSection { margin-top:12px; }
  
  /* Admin Section */
  .boardsAdmin { background:#e6eefa; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box; margin:20px auto 0 auto; max-width:100%; padding:12px; width:990px; }
  .boardsAdminHeader { background:#333; border-radius:4px 4px 0 0; margin:-12px -12px 0 -12px; padding:12px; color:#fff; }
  .boardsAdminHeader > a { color:#fff; float:right; }
  @media only screen and (max-width:479px) {
    .boardsAdminHeader > a { float:none; display:block; margin-bottom:8px; }
  }
  .boardsAdminLinks { padding-bottom:12px; }
  .boardsAdminLinks li { display:inline-block; margin:8px 12px 0 0; }
  .boardAdminNotification { margin:12px 0 -8px; text-align:center; }
  .boardObjection { padding:10px; word-wrap:normal; word-break:break-word; }
  
  /* Admin edit message page */
  .editMessageAuthorInfo { line-height:28px; }
  #breadCrumb + #catList { margin-top:12px; }
  .editBoardAttachment { border:1px solid #bbb0a3; display:inline-block; margin-right:12px; padding:8px; text-align:center; }
  .editBoardAttachment + .editBoardAttachment,
  .editBoardAttachment .postAttachment > div { margin-top:8px; }
  
  /* Report Abuse Page */
  #reportAbuseForm { max-width:600px; }
  
  /* Report Manage Spam */
  #dg_messages { word-break:break-word; }
  #dg_messages a { word-break:normal; }
  .yui-panel-container { max-width:95%; overflow:hidden; }
  
  /* Admin Delete Board */
  #deleteBoardsForm .alert { margin-top:0; }
  
  /* Admin Delete Message */
  #deleteMessageForm p strong { display:inline-block; width:100px; }
  
  /* Admin Edit Board Info Page */
  .editBoardInfoPage .mbformtext { min-height:200px; }
  
  /* Boards Popup Pages */
  .boardsPopup { padding:12px; }
  .boardsPopup :target { -webkit-animation:myTarget 5s; animation:myTarget 5s; }
  @-webkit-keyframes myTarget {
    10%  { background-color: transparent; }
    20%  { background-color: #ffff96; }
    80%  { background-color: #ffff96; }
  }
  @keyframes myTarget {
    10%  { background-color: transparent; }
    20%  { background-color: #ffff96; }
    80%  { background-color: #ffff96; }
  }
  .boardsPopup li a { text-decoration:underline; }
  .boardsPopup li a.bold { text-decoration:none; }
  .boardsPopup p.bold { margin-top:20px; }
  .boardsPopup h2 + p.bold { margin-top:8px; }