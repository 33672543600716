.busy-container {
  position: relative;

  .busy-animation-layer {
    display: none;
    position: absolute;
    @include fill();
  }

  &.busy {
    .busy-animation-layer {
      display: flex;
      justify-content: center;
      align-content: center;
      background: rgba(255, 255, 255, 0.7);
    }

    .busy-container .busy-animation-layer {
      display: none;
    }
  }
}
