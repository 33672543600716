.widget.choose-category {
  ul.breadcrumbs {
    > li {
      display: inline-block;
      margin-right: 4px;
      &::after {
        content: '>';
        margin-left: 4px;
      }
      &:last-child::after {
        content: '';
      }
    }

    button.root {
      font-weight: bold;
    }
  }

  ul.folders {
    > li {
      display: block;
    }
  }

  .content.multi-column {
    display: flex;
    flex-direction: row;
    > ul {
      margin-right: 16px;
    }
  }
}
