.advanced-search.page {
  .tip {
    strong::after {
      content: ': ';
    }
  }

  .pagination {
    margin-bottom: 8px;
  }

  input[type="search"] {
    border: 1px solid $input-border;
    transition: border-color 250ms;
  }

  select {
    border: 1px solid $input-border;
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  @media screen and (max-width: 690px) {
    .search-row {
      flex-direction: column;
      align-items: baseline;

      .ancCol {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .search-row {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .ancCol {
        padding-left: 20px;
      }
    }
  }

  &.has-simple-search-form .page-alert {
    margin-right: 32px;
    margin-bottom: 16px;
  }

  section.search-form {
    .controls {
      padding-left: 0;
    }

    fieldset {
      .field {
        display: inline-block;
        margin-top: 20px;
      }

      &.normal {
        .row {
          display: flex;
          justify-content: space-between;
        }

        .field-keyword, .field-subject, .field-classification, .field-posted-within {
          width: calc(50% - 16px);
        }

        .field-author, .field-surnames {
          width: 70%;
        }

        .field-use-soundex {
          width: calc(30% - 16px);
          margin-left: 16px;

          input {
            margin-right: 4px;
          }
          .checkbox:not(:checked)+label:before{
            border: 1px solid $input-border;
          }
        }
      }

      &.compact {
        .field {
          width: calc(25% - 16px);
          margin-right: 16px;
        }

        .field-keyword {
          width: 30%;
        }
      }
    }
  }

  .results {
    &.has-results-count .number-of-messages {
      font-size: 0.6em;

      &::before {
        content: ' - ';
      }
    }


    .search-results-list {
  
      li {
      border-top: 1px solid #c9c5b9;
      padding-bottom: 16px;
      padding-top: 16px;
      border: 1px solid red;

        & .item-header {
          display: flex;
          justify-content: space-between;
        }

        a {
          text-decoration: underline;
        }

        .item-title {
          font-weight: 700;
        }

        > div {
          margin-top: 10px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
	  
    } 
  }
}

.search-results-thread {
		text-decoration: blink !important;
		color: black;
}
	
.advanced-search-form__row {
  display: flex;
  align-items: flex-end;
}

.advanced-search-form__input {
  display: block;
  padding-left: 20px;
}

.keywords-search {

}

.keywords-search__content {
  display: flex;
  align-items: flex-end;
}

.keywords-search__navigation {
  display: block;

  button {
    margin: 0 8px;
  }
}

.keywords-search__title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
  padding-bottom: 10px;
}

.keywords-search__label {
  color: #181a1c;
  display: block;
  font-weight: 700;
  line-height: 1.26;
  padding-bottom: .125em;
}

.keywords-search__input {
  width: 270px;
}

.keyword-search__options {
  margin-top: 14px;
  display: flex;

  #current-board {
    margin-left: 20px;
  }
}


.keywords-search {
  #field-keyword {
    width: 100%;
  }

  .keyword-search-main {
    display: flex;
    align-items: flex-end;
    .navigation {
      margin-left: 8px;
      .advanced-search-link {
        margin-left: 8px;
      }
    }
  }

  .search-path {
    margin-top: 16px;
    > div {
      margin-right: 8px;
      display: inline-block;
    }
  }
}

.advanced-search.has-breadcrumbs {
  .breadcrumbs {
    margin-top: 20px;
  }
}