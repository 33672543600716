
.thread-author {
  position: relative;
  display: inline-flex;
  align-items: center;
  color:  $link-color;
  line-height: 1.1;

  a:first-of-type{
    margin-right: 4px;
  }

  &.active {
    opacity: .5;
    pointer-events: none;
    @include spinner-link-favorite;
  }

  .progress-linear {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
  }
}

.thread-author__img {
  width: 15px;
  height: 20px;
  overflow: hidden;
  margin-right: 5px;
  img {
    height: 100%;
  }
}

.thread-author__link {
  &.disabled {
    color: #181a1c;
  }
}

.thread-author__star {
  position: relative;
  font-size: 0;
  border: 0;
  width: 16px;
  height: 16px;
  margin: 0 5px;
  background: 0;
  &::before {
    content: "\e60d";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }

  &.added::before {
    content: "\e60b";
  }
}
