.process {
  position: relative;
  min-height: 70px;
}

.process__wrap {
  pointer-events: none;
  opacity: .5;
  height: inherit;
  

}
.process__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.process__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;

  .progress {
    margin-top: 0;
  }
}
