.progress {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 80vh;

  &--min-wrap {
    height: auto;
  }

  &.boards-info {
    position: relative;
    top: 20px;
  }
}