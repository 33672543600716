.page.page-boards {
  max-width: 100%;
  margin: 0 auto;
  padding-top: $block-bottom-space;

  .page__home-link {
    margin-top: $block-bottom-space
  }

  .page__breadcrumb {
    margin-bottom: $block-bottom-space;
  }

  .page__header {
    text-align: center;
    padding: 10px;
  }

  .page__title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    padding: 5px 0;
    position: relative;
  }

  .page__desc {
    font-size: 20px;
    text-align: center;
  }

  .page__content {
    padding: 0px 20px;

    &--loading, &--error {
      min-height: 400px;
    }
  }


  .page__section {
    & > * {
      @include block-view;
      margin-top: 20px;
      &.page__section-advertising {
        background: none;
        border: 0;
        border-radius: 0;
        padding: 0px;
      }
    }

    & > .aside-tools {
      margin-top: 0px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

 @media (max-width: 768px){
 &--category-board,
  &--thread {
    & .page__content {
		padding-right: 20px !important;
	}
  }
  
  #dartContentTop {
    display: none;
  }
  }

  &--category-board,
  &--thread {
    & .page__content {
      position: relative;
      padding-right: 330px;

      &--error {
        padding: 0;
      }

      .page__section {
        &:nth-of-type(4) {
          position: absolute;
          top: 0;
          right: 0;
          width: 317px;
          margin-top: 0;
        }
      }
	  
	 @media (max-width: 768px){
	.page__section {
        &:nth-of-type(4) {
          width: 100%;
          padding-top: 20px;
		      position: relative;
        }
      }
    }
    
      .nav-menu--tools-admin {
        display: block;
        .menu-nav__item {
          display: block;
        }

        &.vertical {
          margin-top: 0;
        }
      }
    }
  }

  &--category-board {
    .page__section {
      &:nth-of-type(2){
        margin-top: 50px;
        position: relative;
      }
    }
  }

    &--thread {
    .page__section {
      &:nth-of-type(2) {
        margin: 75px 0 50px 0;
      }
    }
  }

  &--surname-page {
    .page__section {
      & > * {
        border: 0;
        padding: 0;
        border-radius: 0;
      }
    }
  }

  &--search-results {
    .paging:first-of-type {
      border-top: 0;
      padding-top: 0;
      margin-bottom: 10px;
    }
    .con.results {
      padding-bottom: 35px;
      &.has-results-count {
        padding-bottom: 20px;
      }

      .number-of-messages {
        font-size: 16px;
      }
    }
    .search-results-list li {
      border-top: 1px solid #c9c5b9;
      padding-bottom: 16px;
      padding-top: 16px;
      .item-header {
        display: flex;
        flex-wrap: wrap;
      }

      .item-name-path {
        margin-top: 10px;
        display: inline-block;
      }
      a {
        text-decoration: underline;
      }

      .item-title {
        font-weight: 700;
      }

      .item-by {
        display: flex;
        margin-left: auto;
        padding-left: 30px;
        white-space: nowrap;

      }
      .item-author {
        display: flex;
        margin-left: 3px;
        .user-link {
          margin-left: 2px;
        }

        .user-link__name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 350px;
        }
      }
      > div {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

// ====== admin cases
// admin page delete board-category
.page.admin-view.change-board.operation-.delete-category {
  .conBody {
    @include block-view;
  }
}

.page.move-thread {
  .subject,
  .author,
  .posted,
  .path {
    margin-bottom: 0;
  }
}

.page.author-posts {
  .board-threads .subject {
    margin-right: 3px;
  }
  .author-posts-links-top {
    display: flex;
    flex-wrap: wrap;
  }
  .sort {
    display: flex;
    flex-wrap: wrap;
    ul {
      display: flex;
      margin-left: 5px;

      li {
        position: relative;
        &:first-child {
          margin-left: 0;
          &::before {
            display: none;
          }
        }
        margin-left: 10px;

        &::before {
          content: '|';
          position: absolute;
          top: 50%;
          transform: translateY(-70%);
          left: -7px
        }
      }
    }
  }

  .page-number {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-left: 20px;
  }

  .widget.time.last-post {
    margin-left: 5px;
  }
}

.page.admin-view.node-options.has-breadcrumbs {
  .breadcrumbs__list {
    display: block;
  }
}



.page.advanced-search.mode-advanced {
  .r-location {
    margin-bottom: 20px;
  }
}

.page.board-info.edit {
  .attachments {
    margin-top: 10px;
  }

  .actions {
    margin-top: 10px;
    .cancel {
      margin: 0px 20px;
    }
  }
}
