


.s-result-form__header {
  display: flex;
  align-items: flex-end;
}

.s-result-form__title {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 0;
  padding-bottom: 10px;
}

.s-result-form__label {
  width: 100%;
  margin-right: 20px;
}
.s-result-form__field-wrap {
  margin-right: 20px;

  input {
    font-weight: normal;
    border: 1px solid $input-border;
  }
}
.form-filed__select-wrap {
  select {
    border: 1px solid $input-border;
  }
}
.s-result-form__header-btns {
  display: inline-flex;
  align-items: center;
  padding-bottom: 2px;
}
.s-result-form__label-value {
  display: inline-block;
  margin-bottom: 3px;
}

.s-result-form__forms-btns,
.s-result-form__header {
  & .btn {
    margin-right: 12px;
    &--link {
      font-size: 16px;

      &.hide-btn:focus {
        outline: 2px solid #d37730;
        text-decoration: underline;
        color: $link-color-hover;
      }

      &.hide-btn:hover {
        color: $link-color-hover;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.s-result-form__forms-btns {
  margin-top: 20px;
}

.s-result-form__radio-btns {
  margin-top: 20px;
}

.s-result-form__full {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-end;
}


.s-result-form__field {
  width: 30%;
  margin-top: 20px;
  margin-right: 20px;

  &--checkbox {
    width: auto;
    margin-bottom: 5px;
  }
}

.form-filed__title {
  color: #181a1c;
  display: block;
  font-weight: 700;
  line-height: 1.26;
  padding-bottom: .125em;
}

.s-result-form__full {
  .s-result-form__forms-btns {
    width: 100%;
  }
}
