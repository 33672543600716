.page.move-thread {
  .field-view {
    display: block;
    .label {
      margin-right: 4px;
      &::after {
        content: ':';
      }
    }
    &.author {
      display: flex;
    }
  }

  .field {
    margin-top: 8px;
    .label-text::after {
      content: ':';
    }
  }

  .actions {
    margin-top: 8px;
    button:first-child {
      margin-left: 0;
    }
  }
}
