.list-replies {}

.list-replies__item {
  border: 1px solid #d0c4a9;
  border-radius: 3px;
  margin-top: 8px;

  .thread-detail {
    text-align: right;
  }
}

.list-replies__item-head {
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-column-gap: 2%;
  background: #eee;
  padding: 8px;
  border-bottom: 1px dotted #d0c4a9;
}


.list-replies__subject-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-replies__indicator {
  margin-right: 4px;
}

.list-replies__item-body {
  padding: 8px;
}

.list-replies__tools {
  display: flex;
  justify-content: flex-end
}