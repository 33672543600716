.user-link {
  display: flex;
  align-items: center;
  & .user-link__img {
      margin-right: 3px;
      display: block;
      width: 15px;
      height: 20px;
  }
}

.user-link__name {
  line-height: 1.1;
}
