.categories__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.categories__title {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 10px;
  width: 65%;
  svg {
    margin-right: 5px;
  }
}

.categories__table {
  margin-top: $block-top-space;
}

.categories__notice {
  @include block-view;
  margin-top: $block-top-space;
  margin-bottom: 12px;
}

.categories__notice-info {
  display: flex;
  align-items: center;
  svg {
    color: #71b6ce;
    font-size: 25px;
    margin-right: 10px;
  }
}

.categories__footer{
  margin-top: 10px;
}
