
.surname-result__title {
  @include title-block;
}

.surname-result__row {
  align-items: center;
  border-bottom: 1px solid #ececec;

  &--header {
    font-weight: 700;
    border-bottom: 2px solid #c9c5b9;
  }

  .surname-result__col {
    padding: 2px 8px;
    box-sizing: border-box;
    width: 45%;
  
    &:nth-of-type(2) {
      width: 10%;
    }
    &:nth-of-type(3) {
      width: 10%;
    }
    &:last-of-type {
      width: 35%;
    }
  }
}

.surname-result__link {
  span {
    margin-right: 5px;
  }
}

.surname-result__empty {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  color: #181a1c;
  padding: 20px;

  span {
    color: $err-color;
    margin-right: 5px;
    font-size: 17px;
  }
}

.surname-result__paging {
  margin-bottom: 10px;
  &:last-of-type {
    margin-top: 10px;
    margin-bottom: 0;
  }
}