
.form-thread-detail {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -20px;
    right: -20px;
    background: #f5f5f5;
    height: 1px;
  }
}
.form-thread-detail__title {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 8px;
}

.form-thread-detail__content {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}

.form-thread-detail__user {
  &.hide {
    color: #181a1c;
  }
}
.form-thread-detail__subject {
  margin-bottom: 5px;
}
.form-thread-detail__user-img {
  width: 15px;
  height: 20px;
  overflow: hidden;
  margin-right: 5px;

  img {
    height: 100%;
  }
}

.form-thread-detail__info {
  text-align: right;
  color: #555;
  line-height: 1.8;
}

.form-thread-detail__message {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dotted #d0c4a9;
}