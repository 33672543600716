.abuse-form {
  width: 100%;
  .abuse__field-wrap {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
  .abuse__btns-wrap {
    margin-top: 16px;
    .form__submit {
      margin-right: 5px;
      border: 1px solid transparent;
      border-radius: 3px;
      box-shadow: none;
      color: #fff !important;
      background-color: #e3720f;
      cursor: pointer;
      display: inline-block;
      font-family: inherit;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 18.9px;
      min-height: 30px;
      padding: 5px 12px;
      text-align: center;
      text-decoration: none !important;
      text-indent: 0;
      transition: padding-left 0.2s;
      vertical-align: middle;
      &:disabled {
        background-color: #a9a9a9;
        border-color: transparent !important;
        opacity: 0.6;
        outline: none !important;
        pointer-events: none;
        -webkit-text-fill-color: #fff;
      }
    }
    .form__link {
      margin-left: 5px;
    }
  }
}

.abuse__field {
  .abuse__textarea {
    &--error {
      border: 1px solid #c14139;
    }
    &--success {
      border-color: #42860e;
    }
  }
  .abuse__select {
    &--success {
      border-color: #42860e;
    }
  }
}

.abuse__title {
  display: inherit;
  line-height: 1.26;
  ////align-items: center;
  word-break: break-all;
  word-wrap: break-word;
  &--error-message {
    color: #c14139;
    font-weight: normal;
    line-height: 0.9;
    margin-left: 8px;
  }

  &--error-message::before {
    content: "\e668";
    display: inline-block;
    font-size: 13px;
    margin-right: 0;
    text-indent: 0;
    top: 0;
    width: 1.25em;
  }

  &--success::after {
    color: #9cbe30;
    content: "\e656";
    font: 14px/0 "ancestry-icon";
    margin-left: 8px;
    position: static;
  }

  &--required::after {
    bottom: -10px;
    color: #c14139;
    content: "*";
    font-size: 2em;
    font-weight: bold;
    line-height: 0;
    position: relative;
  }

  &--error {
    color: #c14139;
  }
}
