.page.manage-list {
  .page-number {
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
  }

  .list-wrapper > .busy-container {
    margin-top: 8px;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    &.busy {
      border-color: transparent;
    }

    > .busy-content > ul > li {
      padding: 4px 12px;
      border-bottom: 1px solid rgba(206, 206, 206, 0.5);
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .manage-item {
    .fieldset {
      .field-view {
        display: inline;
        margin-bottom: 0;
        &::after {
          content: ' '
        }

        .label {
          &::after {
            display: none;
          }
        }
        .value {
          margin-left: 3px;
        }
      }

      .field-number {
        margin-right: 4px;
        &::after {
          content: '.';
        }
      }

      .field-view.name,
      .field-view.creatorName,
      .field-view.requestDate {
        margin-right: 8px;
      }

      .field-view.name,
      .field-view.path,
      .field-view.comment,
      .field-view.status {
        display: block;
      }

      .field-view.email + .field-view {
        margin-top: 16px;
      }
    }

    .judge-button {
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
}

.judge-modal {
  .judge-modal-content {
    .field-number {
      display: none;
    }

    width: 500px;
    padding-bottom: 16px;
  }

  .objection-message {
    margin-top: 24px;
  }

  .fieldset {
    //.field-view .label::after {
    //  content: ': ';
    //}

    display: table;
    > * {
      display: table-row;
      > * {
        display: table-cell;
        &:first-child {
          text-align: right;
          padding-right: 4px;
        }
      }
    }
  }

  .manage-actions {
    margin-top: 8px;
    button {
      margin-right: 8px;
    }
  }
}

.board-fields {
  width: 100%;
  > .field {
    margin-bottom: 8px;
    label:first-child {
      width: 120px;
    }
    &.type-checkbox label {
      margin-left: 8px;
      display: inline-block;
      font-weight: normal;
    }
    &.type-text {
      display: flex;
      label {
        text-align: right;
        padding-right: 4px;
        &:after {
          content: ':';
        }
      }
    }
  }
}

.modal-judge-right {
  .board-fields {
    display: none;
  }
}

.manage-form {
  margin-top: 8px;
}

.objection-message > h3 {
  color: #566C11;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
}
