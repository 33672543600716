
.thread-link {
  display: inline-flex;
  align-items: center;
  background: rgba(0,0,0,0.6);
  padding: 2px 5px;
  box-sizing: border-box;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: rgba(0,0,0,0.75);
    color: #fff;
    text-decoration: none;
  }

  span {
    margin-right: 5px;
  }

  &:active {
    opacity: .5;
  }

  &.width-auto {
    width: auto;
  }

  .thread-link__text {
    white-space: nowrap;
  }
}

