.surname-page {}

.surname-page__wrap {
    @include wrap-desktop;
}

.surname-page__title {
  @include title-page;
}

.surname-page__letters {
  @include block-view;
}

.surname-page__content {
  padding-top: 50px;
  > div {
    margin-bottom: 16px;
  }
}


.surname-page__content-result {
  @include block-view;
  .paging {
    border: 0;
  }
}


.surname-page__admin-tools {
  margin-bottom: 10px;
  color: $link-color;
}