.paging {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  border-top: 1px solid #c9c5b9;
  line-height: 32px;
  padding-top: 4px;
}
.spam-control {
  padding: 5px;
  border: 1px solid #c9c5b9;
  margin-bottom: 5px;
}

.paging__btn {
  padding: 5px;
  border: 1px solid grey;
  background: black;
}


.paging__count-list {
  .MuiInputBase-root.MuiInput-root.MuiInput-underline {
    
    &::before {
      top: 0;
      transition: unset;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 3px;
    }

    &::after {
      display: none;
    }

    & .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
      padding: 5px 25px 5px 10px;
      border: 1px solid $input-border;
      &:focus{
        outline: 2px solid $focus-color;
      }
    }
  }
}

.paging__count-text {
  padding-left: 5px;
}

.paging__btn {
  position: relative;
  width: 30px;
  height: 30px;
  background: #EBEBEB;
  color: var(--ui-text-color);
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s ease;
  box-sizing: border-box;
  
  &:hover {
    box-shadow: 0px 1px 3px rgba(#a9a9a9, .7);
  }
  &:focus {
    outline: 2px solid $focus-color;
    outline-offset: 2px;
    background: #EBEBEB;
  }

  &.disabled {
    cursor: default;
    box-shadow: 0px 1px 3px rgba(#a9a9a9, .3);
    opacity: .4;
    &:hover {
      box-shadow: 0px 1px 3px rgba(#a9a9a9, .3);
    }
  }
}

.paging__info {
  padding: 0px 5px;
}