.abuse-content {
  .abuse__header-wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted #d0c4a9;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}

.indicator{
  margin: auto 4px auto 0;
}

.abuse__header {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.abuse__user-link {
  display: flex;
  margin-top: 8px;
  .abuse__user-img {
    width: 15px;
    height: 20px;
    margin-right: 4px;
  }
}

.abuse__list {
  display: flex;
  flex-direction: column;
  width: 50%;
  .abuse__list-item {
    text-align: end;
    color: #555;
    line-height: 1.8;
    span {
      @include capitalize-first-letter;
    }
  }
}
