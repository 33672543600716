@mixin page-section {
  margin-top: 0;
  background-color: transparent;
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
@mixin abuse-form {
  margin-top: 0;
  background-color: transparent;
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
}

.page.page-boards {
  &--abuse {
    .page__content {
      .page__section {
        @include page-section;
        .abuse {
          display: grid;
          grid-gap: 10px;
          grid-template-columns: 1.2fr 2fr;
          grid-template-rows: 1fr;
          background-color: transparent;
          border: none;
          .abuse__form-wrap {
            @include abuse-form;
          }
          .abuse__content-wrap {
            align-self: start;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            padding: 20px;
          }
        }
      }
    }
  }
  &--abuse-no-content {
    .page__content {
      .page__section {
        @include page-section;
        .abuse {
          display: flex;
          justify-content: center;
          background-color: transparent;
          border: none;
          .abuse__form-wrap {
            width: 550px;
            @include abuse-form;
          }
          .abuse__content-wrap {
            display: none;
          }
        }
      }
    }
  }
}
