
.widget {

  &.form {
    .actions {
      margin-top: 20px;
      .cancel {
        margin: 0 15px;
      }
    }
  }
  &.field-view {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;

    & .label {
      position: relative;

      &::after {
        bottom: -10px;
        margin-left: 4px;
        color: #c14139;
        content: '*';
        font-size: 2em;
        font-weight: bold;
        line-height: 0;
        position: relative;
      }
    }
    & .value {
      width: 100%;
    }

    &.subject,
    &.author,
    &.posted,
    &.path {
      & .label {
        &::after {
          content: ' ';
          color: #000;
          font-size: inherit;
          bottom: 0;
          margin-left: 0px;
        }
      }
    }

    &.author {
      display: flex;



      & .value {
        width: auto;
        & .profile-link {
          display: flex;
          align-items: center;

          .avatar-container {
            position: relative;
            width: 15px;
            height: 20px;
            margin-right: 5px;
            overflow: hidden;

            img {
              display: block;
              max-width: 100%;
              height: 100%;
            }
          }
        }
      }





    }

  }

  & .actions .ancBtn.orange{
    margin-right: 12px;
  }
}
