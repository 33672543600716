.categories-page {
  .empty {
    margin-top: 16px;
  }
}

.categories-page__wrap {
  @include wrap-desktop;
  padding-top: 20px;
}

.categories-page__title {
  @include title-page;
  margin-top: 5px;
  margin-bottom: 40px;
}

.moderation-warning {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.categories-page {
  .gatewayAddress {
    margin-top: 8px;
  }

  article > .alert {
    margin-top: 20px;
  }
}

.categories__admin-board{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  top: -37.5px;
}
