.manage-spam.page {
  section.form .controls {
    padding-left: 0;
  }

  section.results .controls {
    display: flex;
    justify-content: space-between;
    .select-all {
      font-weight: normal;
      input {
        margin-right: 4px;
      }
    }
  }

  tr {
    td.username {
      white-space: nowrap;
    }

    td.path {
      min-width: 220px;
    }
  }
}

.boards-modal.thread-preview h2 {
  min-width: 400px;
}
