// colors
$text-color: rgb(24, 26, 28);
$link-color: var(--ui-link-color);
$bck-color: #f5f5f5;
$bck-btn: #e3720f;
$err-color: #c14139;
$success-color: rgb(66, 134, 14);
$border-field-enable: rgb(66, 134, 14);
$block-bottom-space: 20px;
$block-top-space: 20px;
$dark-red: #600;
$input-border: #8e8e8e;
$focus-color: #c66d2a;

// hover state
$bck-h: rgba(245,245,245,0.5);
$link-color-hover: var(--ui-link-hover-color);
$btn-submit-color-hover: #bf5500;


// for libs
$font-path: '~react-widgets/lib/fonts';
$img-path: '~react-widgets/lib/img';

