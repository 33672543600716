
.thread-form {
  position: relative;

  &--reply,
  &--create,
  &--author {
    width: 100%;
    margin: 0 auto;

    .form-filed__inp-textarea {
      min-height: 160px;
    }

    .thread-form__field--classification {
      width: 300px;
    }

    .form-filed__select-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      select {
        width: 200px;
        margin-right: 5px;
      }
    }
  }
  &--alien {
    display: grid;
    grid-template-columns: 25% 25% 1fr 1fr;
    align-items: center;
    grid-column-gap: 20px;

    & .thread-form__board-link {
      grid-column: 1 / span 4;
    }

    .thread-form__user {
      grid-column: 3;
    }
    .thread-form__posted {
      grid-column: 4;
    }

    .thread-form__field {
      &--subject {
        grid-column: span 2;
      }

      &--surnames {
        grid-column: span 2;
      }

      &--classification {
        grid-column: span 2;
      }

      &--textarea {
        grid-column: span 4;
      }
      &--checkbox {
        grid-column: span 4;
      }
      &--attachemnt {
        grid-column: span 4;
      }
    }
    .thread-form__btns {
      grid-column: 1;
    }

    .form-filed__inp-textarea {
      min-height: 150px;
    }

  }

  .form-filed__title,
  .thread-form__user-title span,
  .thread-form__posted-title span {
    color: #181a1c;
    display: block;
    font-weight: bold;
    line-height: 1.26;
    margin-bottom: 2px;
  }

  .thread-form__posted-time {
    margin-top: 0;
  }

  .form-filed__description {
    color: #3a3f45;
    margin-top: 3px;
  }

  .thread-form__btns {
    display: flex;
    align-items: center;
  }
  .thread-form__btn-submit {
    margin-right: 15px;
  }
}

.thread-form__board-link {
  margin-bottom: 10px;
}

.thread-form__progress {
  position: fixed;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  background: rgba(0,0,0, .3);
  z-index: 10001;

  .progress--min-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

.thread-form__field,
.thread-form__user,
.thread-form__posted,
.thread-form__user-info {
  margin-bottom: 20px;
}

.form-field__title-required {
  position: relative;

  &::after {
    bottom: -10px;
    color: #c14139;
    content: '*';
    font-size: 2em;
    font-weight: bold;
    line-height: 0;
    position: relative;
  }
}


.thread-form__btn-submit,
.thread-form__btn-preview,
.thread-form__cancel-link {
  margin-right: 15px;
}