.thread {
  position: relative;
}

/*
* Navigation arrows
*/

.thread__prev-next {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;


  .thread__next {
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid $link-color;
      border-bottom: 0;
      border-left: 0;
      box-sizing: border-box;
      transform: translateX(3px) rotate(45deg);
      transition: .4s ease;
    }

    &:hover {
      text-decoration: none;
      &::after {
        opacity: .7;
        transform: translateX(4px) rotate(45deg);
      }
    }
  }

  .thread__prev {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid #005c7d;
      border-bottom: 0;
      border-right: 0;
      box-sizing: border-box;
      transform: translateX(-3px) rotate(-45deg);
      transition: .4s ease;
    }

    &:hover {
      text-decoration: none;
      &::before {
        opacity: .7;
        transform: translateX(-4px) rotate(-45deg);
      }
    }
  }

  &.top {
    top: -35px;
  }

  &.bottom {
    bottom: -35px;
  }
}

.thread__header {
  border-bottom: 1px dotted #d0c4a9;
  padding-bottom: 8px;
}

.thread__header-top,
.thread__header-bottom {
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-column-gap: 2%;
}
.thread__header-top {
  grid-template-columns: 61% 37%;
}


.thread__header-top,
.thread__header-notice {
  margin-bottom: 20px;
}

.thread__header-top {
  align-items: center;
}

.thread__title {
  @include title-block;
  margin-bottom: 0;

  span {
    margin-right: 5px;
  }
}

.thread__link-new {
  display: flex;
  justify-content: flex-end;
}

.thread__subject-thread {
  line-height: 1.35;
  margin-bottom: 10px;
}

.thread__header-detail {
  text-align: right;
}

.thread__body-meta {
  position: relative;
  padding: 10px 0px;
  &::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -20px;
    right: -20px;
    height: 1px;
    background: #f5f5f5;
  }
}

.thread__body-msg {
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.thread__body-attachments {
  margin-top: 10px;
}

.thread__body-tools {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.thread__content {
 padding-top: 20px;
}

.thread__content-header {
  .paging {
    border: 0;
    border-bottom: 1px solid #c9c5b9;
    padding-bottom: 10px;
    justify-content: end;
  }
}
.thread__btn-toggle {
  border: 0;
  color: $link-color;
  background: none;
  &:hover {
    text-decoration: underline;
  }
}


.thread__content-settings {
  display: flex;
  align-items: center;
}

.thread__content-indicator {
  margin-top: 5px;
}

.thread__btn-toggle {
  margin-right: 15px;
}

.thread__dropdown {
  position: relative;
  padding: 2px 30px 2px 5px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;

  &.active {
    border: 1px solid rgba(0,0,0,0.5);

    &::before {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 5px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: translateY(-50%);
    transition: .3s ease;
    transform-origin: 50% 50%;
  }

  .thread__dropdown-btn {
    border: 0;
    background: 0;
    padding: 1px 5px;
    line-height: 1;
  }
}

.thread__dropdown-select {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  opacity: 0;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 3px;
  transition: .4s ease;
  animation: showDropdown .3s ease forwards;
  height: 0;
  overflow: hidden;
}


@keyframes showDropdown {
  100% {
    opacity: 1;
    height: 54px;
    transform: translateY(0%);
    box-shadow: 0px 2px 5px rgba(0,0,0, .2);
    background: #fff;
  }
}

.thread__dropdown-select {
  .thread__dropdown-btn {
    position: relative;
    padding: 5px 0px;
    padding-left: 20px;
  }
}


.thread__content-paging {
  &.bottom .paging {
    border-bottom: 0;
    justify-content: space-between;
  }
}

.thread__admin-board {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  top: -64px;
}
