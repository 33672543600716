.pagination {
  display: flex;
  justify-content: space-between;
}

.page-size {
  select {
    width: 60px;
    margin-right: 4px;
    border: 1px solid $input-border;
  }

  .hidden {
    display: none;
  }
}

.page-number {
  display: flex;
  .current-page {
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
  }

  .current-page-of {
    margin: 0 4px;
  }

  .pagination-link {
    background: #EBEBEB;
    &:focus, &:focus-visible {
      outline: 2px solid $focus-color;
    }
    &.ancBtn.silver:focus, &.ancBtn.silver:focus-visible{
      background: #EBEBEB !important;
    }
  }

  &.editable {
    .current-page-number {
      border: 1px solid gray;
      width: 30px;
      &:focus {
        border-color: black;
        &[disabled] {
          border-color: transparent;
        }
      }
    }
  }
}