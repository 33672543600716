.error-page{
  width: 990px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 70px 20px;
}

.link-back{
  text-align: center;
}

.error-content{
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  padding-top: 50px;
}

.error-content__info{
  place-self: end;
  width: 290px;
  ul{
    margin-top: 8px;
    li{
      padding-top: 8px;
    }
  }
}

.error-content__img{
  place-self: start;
  width: 290px;
}





