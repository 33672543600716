

.page.has-breadcrumbs {
  .breadcrumbs {
    margin: 10px 0;
  }
  
  .breadcrumbs__list {
  }
  
  .breadcrumbs__link--active {
    font-weight: bold;
  }
  
  .breadcrumbs__item {
    display: inline;
  
    &::after {
      color: #5e6770;
      margin-left: 4px;
      margin-right: 4px;
      font-family: monospace;
      content: '>';
    }
  
    &:last-of-type {
      .breadcrumbs__link::after {
        display: none;
      }
  
      &::after {
        display: none;
      }
    }
  }
}

.breadcrumbs__links {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__item {
  margin-right: 20px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-45%) rotate(-45deg);
    right: -10px; 
    color: #5e6770;
    font-family: monospace;
    width: 5px;
    height: 5px;
    border: 1px solid  #5e6770;
    border-left: transparent;
    border-top: transparent;
  }

  &:last-of-type {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
}

.breadcrumb__link {
  &.current {
    font-weight: 700;
  }
} 