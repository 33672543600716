
.notice-favorite {
  position: relative;
  padding: 20px 30px 20px 57px;
  margin-bottom: 12px;
  box-shadow: 0 1px 2px rgba(0,0,0, .1);

  &.alert::before {
    content: "\e656";
    color: #9cbe30;
  }

  &--error.alert::before {
    content: "\e668";
    color: #ff8e00;

  }
}

.notice-favorite__btn-hide {
  position: absolute;
  top: 10PX;
  right: 10px;
  width: 15px;
  height: 15px;
  border: 0;
  background: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background:rgba(0,0,0, .3);
    transition: .2s ease;
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &:hover::before {
    transform: translateY(-50%) rotate(45deg);
  }
  &:hover::after {
    transform: translateY(-50%) rotate(135deg);
  }
} 