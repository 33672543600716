#boards-ui {
  // Hiding elements that available for admins only
  .admin {
    display: none;
  }

  &.is-admin .admin {
    display: initial;
  }

  .loading-current-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin-top: 80px;
    }
  }

  .page.forbidden > .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    a {
      margin-top: 16px;
    }
  }
}
