.surname-letters {
  @include block-view;
}

.surname-letters__title {
  @include title-block;
}

.surname-letters__desc {
  margin-bottom: 10px;
  span {
    font-weight: 700;
  }
}

.surname-letters__item {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 3px 1.5px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}


.surname-letters__link {
  padding: 3px 2px;
  margin-right: 3px;

  &:last-of-type {
    margin-right: 0;
  }
  &--active {
    background: #eff2d3;
    font-weight: 700;
  }
}