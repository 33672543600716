.widget.alert {
  position: relative;
  .alert-close {
    position: absolute;
    top: 0;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 22px;
    opacity: 0.6;
    transition: opacity 100ms ease-out;
    &:hover {
      opacity: 0.95;
    }
  }
}
