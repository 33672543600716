#boards-ui {
  main {
    // Footer at the bottom of page
    min-height: calc(100vh - 180px);
  }

  &.is-admin main {
    min-height: calc(100vh - 370px);
  }

  footer {
    text-align: center;
  }
}
